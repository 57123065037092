import React from 'react'
import { useIntl } from 'react-intl'
import {
  ServiceCustomLink,
  SocialMediaElements,
  ShowOnDesktop,
  ShowOnMobile,
  Icons
} from 'msp-components'

const SOCIAL_MEDIA_CONTENT = [
  {
    iconName: 'Facebook',
    link: 'https://www.google.com'
  },
  {
    iconName: 'Pinterest',
    link: 'https://www.google.com'
  },
  {
    iconName: 'Instagram',
    link: 'https://www.google.com'
  },
  {
    iconName: 'Youtube',
    link: 'https://www.google.com'
  }
]

export const FirstContentSlot = ({
  children
}: {
  children?: React.ReactNode
}) => (
  <ShowOnDesktop className='flex flex-col order-3 col-span-2 p text-20 md:col-span-1 lg:order-none'>
    {children}
    <>
      <ServiceCustomLink
        url='mailto:service@darq.com'
        icon={<Icons iconName='Mail' size='base' />}
        wrapperClassname='!mb-0'
        iconWrapperClassname='font-GoodPro fill-white pt-1'
        textClassname='text-15 tracking-0.6 leading-20 pl-1.5 lowercase'
      >
        service@darq.com
      </ServiceCustomLink>
      <ServiceCustomLink
        url='tel:+49 (0) 800 70000 654'
        icon={<Icons iconName='Telephone' size='base' />}
        iconWrapperClassname='font-GoodPro fill-white pt-1'
        textClassname='text-15 tracking-0.6 leading-20 pt-2 pl-1.5'
      >
        +49 (0) 800 70000 654
      </ServiceCustomLink>
      <SocialMediaElements socialMediaItems={SOCIAL_MEDIA_CONTENT} />
    </>
  </ShowOnDesktop>
)

export const SecondContentSlot = ({
  children
}: {
  children?: React.ReactNode
}) => (
  <ul className='order-1 mb-0 pl-0 md:mb-8 lg:order-none lg:mb-0 lg:pl-10'>
    {children}
  </ul>
)

export const ThirdContentSlot = ({
  children
}: {
  children?: React.ReactNode
}) => (
  <ul className='order-2 mb-0 pl-0 md:mb-8 lg:order-none lg:mb-0 lg:pl-10'>
    {children}
  </ul>
)

export const FourthContentSlot = ({
  children
}: {
  children?: React.ReactNode
}) => {
  const { formatMessage } = useIntl()
  const priceInfoText = formatMessage({
    defaultMessage: '*alle Preise inkl. MwSt. zzgl. Versandkosten',
    id: 'msp.footer.price_info'
  })

  return (
    <ShowOnDesktop className='order-4 col-span-2 text-20 md:col-span-1 lg:order-none'>
      {children}
      <div className='flex flex-row w-full pt-3'>
        <div className='flex flex-col gap-2 pr-3'>
          <ServiceCustomLink
            url='#'
            icon={<Icons iconName='AppleAppStore' asFile width='94px' height='32px' />}
            iconWrapperClassname='fill-white'
          />
        </div>
        <div className='flex flex-col gap-2'>
          <ServiceCustomLink
            url='#'
            icon={
              <Icons iconName='GoogleAppStore' asFile width='108px' height='32px' />
            }
            iconWrapperClassname='fill-white'
          />
        </div>
      </div>
      <div className='text-14 tracking-0.2 text-machine1-50 mt-4'>
        {priceInfoText}
      </div>
    </ShowOnDesktop>
  )
}

export const PreFooterMobileSlot = () => (
  <ShowOnMobile>
    <div className='flex justify-center mt-8'>
      <SocialMediaElements socialMediaItems={SOCIAL_MEDIA_CONTENT} />
    </div>
    <div className='flex flex-col items-center justify-center mt-10'>
      <div className='flex font-normal border-machine1-75 font-GoodPro leading-32'>
        <ServiceCustomLink
          textClassname='font-GoodPro font-normal text-16 tracking-0.6 leading-20 underline lowercase'
          url='mailto:service@darq.com'
        >
          service@darq.com
        </ServiceCustomLink>
      </div>
      <div className='flex font-normal border-machine1-75 font-GoodPro leading-32'>
        <ServiceCustomLink
          textClassname='font-GoodPro font-normal text-16 tracking-0.6 leading-20 underline lowercase'
          url='tel:+49 (0) 800 70000 654'
        >
          +49 (0) 800 70000 654
        </ServiceCustomLink>
      </div>
    </div>
  </ShowOnMobile>
)

export const PostFooterMobileSlot = () => {
  const { formatMessage } = useIntl()
  const priceInfoText = formatMessage({
    defaultMessage: '*alle Preise inkl. MwSt. zzgl. Versandkosten',
    id: 'msp.footer.price_info'
  })

  return (
    <ShowOnMobile>
      <div className='flex flex-col items-center font-Roboo text-14 text-gray92 tracking-0 md:items-start'>
        <div>{priceInfoText}</div>
        <div className='flex pt-6 pb-4'>
          <div className='flex-auto pr-2.5'>
            <ServiceCustomLink
              url='#'
              icon={
                <Icons iconName='AppleAppStore' asFile width='94px' height='32px' />
              }
              iconWrapperClassname='fill-white'
            />
          </div>
          <div className='flex-auto'>
            <ServiceCustomLink
              url='#'
              icon={
                <Icons iconName='GoogleAppStore' asFile width='108px' height='32px' />
              }
              iconWrapperClassname='fill-white'
            />
          </div>
        </div>
      </div>
    </ShowOnMobile>
  )
}
