import React from 'react'
import { transformRegionToMap } from 'msp-components'
import GalleryText from '@/components/molecules/GalleryText/GalleryText'

const GalleryTextWrapper = ({ data, regions }: { data: any; regions: any }) => {
  const content = transformRegionToMap(regions).get('content')
  const imageRegions = transformRegionToMap(regions).get('images')

  const getImages = () => {
    const imageArray = imageRegions?.components?.map((image) => {
      return { link: image?.custom?.content?.image?.path }
    })
    return imageArray
  }

  const images = getImages()

  return (
    <GalleryText
      galleryPosition={data?.galleryPosition}
      images={images || []}
      content={content}
    />
  )
}

export default GalleryTextWrapper
