import React from 'react'
import { Region, transformRegionToMap } from 'msp-integrations'
import { MachineCommerceStageWrapperProps } from './MachineCommerceStageWrapper.types'
import { MachineCommerceStage } from '@/components/organisms/MachineCommerceStage'

const MachineCommerceStageWrapper = ({
  data,
  regions,
  custom
}: MachineCommerceStageWrapperProps) => {
  const content = transformRegionToMap(regions).get('text')

  return (
    <MachineCommerceStage
      product1={{
        id: data?.product1,
        image: custom?.content?.product1Image,
        imageMobile: custom?.content?.product1ImageMobile,
        imageAlt: data?.image1Alt,
        theme: data?.product1Theme
      }}
      product2={{
        id: data?.product2,
        image: custom?.content?.product2Image,
        imageMobile: custom?.content?.product2ImageMobile,
        imageAlt: data?.image2Alt,
        theme: data?.product2Theme
      }}
      headline={data?.headline}
      title={data?.title}
    >
      {content && <Region region={content} hideProgressBar={true} />}
    </MachineCommerceStage>
  )
}

export default MachineCommerceStageWrapper
