import React, { useState, useEffect } from 'react'
import { ScrollTranstionWrapper } from 'msp-components'
import { useWindowSize } from 'msp-integrations'
import FlipCardsContainer from '@/components/organisms/FlipCardsContainer/FlipCardsContainer'
import ProductFlipCard from '@/components/molecules/ProductFlipCard/ProductFlipCard'
import ScrollForMore from '@/components/atoms/ScrollForMore/ScrollForMore'
import ExpandVideo from '@/components/atoms/ExpandVideo/ExpandVideo'
import { HomeStageProps } from './HomeStage.types'

const HomeStage = ({
  className,
  mainUrl,
  mainUrlText,
  productOne,
  productTwo,
  productThree,
  productFour,
  children
}: HomeStageProps) => {
  const [showTiles, setShowTiles] = useState(false)
  const [hideTiles, setHideTiles] = useState(false)
  const { width, breakpoints } = useWindowSize()
  const checkDesktop = () => width && width >= breakpoints.lg
  const [isDesktop, setIsDesktop] = useState(undefined)

  useEffect(() => {
    if (checkDesktop() !== isDesktop) {
      setIsDesktop(checkDesktop())
    }
  }, [width])

  /*
   * showTiles && hideTiles are used to control the animation of the tiles
   * hideTiles cannot be !showTiles, hide and show cannot be animated in the same useEffect
   * in FlipCardsContainer because of gsap async problems
   * I tried many things with no success, if you feel like a superhero and want to fix it, be my guest.
   */

  const handleAnimate = () => {
    setShowTiles(true)
    setHideTiles(false)
  }

  const handleRevert = () => {
    setHideTiles(true)
    setShowTiles(false)
  }

  if (isDesktop === undefined) return null

  if (!isDesktop) {
    return (
      <div>
        <div
          className={
            'relative h-[calc(100vh-66px)] w-screen md:h-[calc(100vh-52px)]'
          }
        >
          {children}
          <div
            className={
              'absolute bottom-0 left-1/2 -translate-x-1/2 select-none transition-transform duration-500'
            }
          >
            <ScrollForMore />
          </div>
        </div>
        <div
          className={
            'hide-scrollbar flex h-auto snap-x snap-mandatory flex-row flex-nowrap space-x-4 overflow-x-auto overflow-y-hidden p-6'
          }
        >
          <ProductFlipCard
            productId={productOne.id}
            productCoverImageUrl={productOne.imgSrc}
            productDetailImageUrl={productOne.backImageSrc}
            imageAlt={productOne.imageAlt}
          />
          <ProductFlipCard
            productId={productTwo.id}
            productCoverImageUrl={productTwo.imgSrc}
            productDetailImageUrl={productTwo.backImageSrc}
            imageAlt={productTwo.imageAlt}
          />
          <ProductFlipCard
            productId={productThree.id}
            productCoverImageUrl={productThree.imgSrc}
            productDetailImageUrl={productThree.backImageSrc}
            imageAlt={productThree.imageAlt}
          />
          <ProductFlipCard
            productId={productFour.id}
            productCoverImageUrl={productFour.imgSrc}
            productDetailImageUrl={productFour.backImageSrc}
            imageAlt={productFour.imageAlt}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className={`relative mb-20 ${className}`}>
        <ScrollTranstionWrapper
          className={
            'h-[calc(100vh-66px)] w-full md:h-[calc(100vh-52px)] md:max-h-[150vw]'
          }
          startAnimationOn={'bottom bottom-=1px'}
          rightContainerWidthPercentage={37}
          onStartAnimation={handleAnimate}
          revert={hideTiles}
        >
          {React.cloneElement(children, {
            playButtonPosition: 'left'
          })}
        </ScrollTranstionWrapper>
        <FlipCardsContainer
          className={'z-10 w-[41vw]'}
          mainLink={mainUrlText}
          mainLinkUrl={mainUrl}
          showTiles={showTiles}
          hideTiles={hideTiles}
          productOne={productOne}
          productTwo={productTwo}
          productThree={productThree}
          productFour={productFour}
        />
        <div
          className={`absolute bottom-0 right-[41vw] cursor-pointer select-none transition-all duration-500 ${
            !showTiles ? 'invisible opacity-0' : 'visible opacity-100 delay-500'
          }`}
          onClick={() => handleRevert()}
        >
          <ExpandVideo />
        </div>
        <div
          className={`absolute bottom-0 left-1/2 -translate-x-1/2 select-none transition-all duration-500 ${
            showTiles ? 'invisible opacity-0' : 'visible opacity-100 delay-1000'
          }`}
        >
          <ScrollForMore />
        </div>
      </div>
    )
  }
}

export default HomeStage
