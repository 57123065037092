import React from 'react'
import { SearchBox, SearchBoxProps } from 'msp-components'

const PuroSearchBox = ({
  className,
  classNameResults = '',
  formRef,
  inputIcon,
  inputPlaceholder,
  inputClassName = '',
  position = 'absolute',
  onResultClick,
  onSearching,
  onSearch,
  onClose,
  showDeleteButton,
  searchValue,
  iconColor,
  isDarkMode
}: SearchBoxProps) => {
  return (
    <SearchBox
      className={className}
      classNameResults={classNameResults}
      formRef={formRef}
      inputIcon={inputIcon}
      inputPlaceholder={inputPlaceholder}
      inputClassName={inputClassName}
      position={position}
      onResultClick={onResultClick}
      onSearching={onSearching}
      onSearch={onSearch}
      onClose={onClose}
      showDeleteButton={showDeleteButton}
      searchValue={searchValue}
      iconColor={iconColor}
      uiConfig={{
        searchIconName: 'Search',
        searchFieldLetterSpacing: 'tracking-0.8',
        resultTitleLetterSpacing: 'tracking-4',
        resultLetterSpacing: 'tracking-3'
      }}
      isDarkMode={isDarkMode}
    />
  )
}

export default PuroSearchBox
