import React from 'react'
import { AmountAttributeIndicatorProps } from './AmountAttributeIndicator.types'

const AmountAttributeIndicator = ({
  className,
  quantity,
  maxAmount = 5,
  height = '12px'
}: AmountAttributeIndicatorProps) => {
  const ValueIndicator = ({ filled = false }: { filled: boolean }) => (
    <svg
      className='rotate-90'
      strokeWidth='1px'
      width={height}
      height={height}
      version='1.1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      {filled ? (
        <g>
          <polygon points='12,1.92 3.12,6.96 3.12,17.04 12,22.08 20.88,17.04 20.88,6.96' />
        </g>
      ) : (
        <g>
          <path d='M12,22.08l-8.88-5.03V6.96L12,1.92l8.88,5.04v10.08L12,22.08z M4.12,16.46L12,20.92l7.88-4.47V7.54L12,3.08L4.12,7.54 V16.46z' />
        </g>
      )}
    </svg>
  )

  return (
    <div
      className={`flex flex-row flex-nowrap items-center space-x-0 ${className}`}
    >
      {Array(maxAmount)
        .fill(0)
        .map((_, idx) => (
          <ValueIndicator key={idx} filled={idx < quantity} />
        ))}
    </div>
  )
}

export default AmountAttributeIndicator
