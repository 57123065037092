// Import SVGs
/*
Steps to add new icons
1. Place the svg file in apps/puro-frontend/app/assets/svg/icons directory.
2. Translate svg to tsx component.
3. Import the svg file below in this file with sorted order
4. Add the new index in the SVGMappings array
5. And you can call you Icon like this: <Icons color="#1a0dab" iconName="Favorite" size="3xl" />
 */
import SVGMapping from './SVGMapping'
import AboIcon from '@/assets/svg/icons/AboIcon'
import AboTextIcon from '@/assets/svg/icons/AboTextIcon'
import BarcodeIcon from '@/assets/svg/icons/BarcodeIcon'
import BasketIcon from '@/assets/svg/icons/BasketIcon'
import BrewingProcessIcon from '@/assets/svg/icons/BrewingProcessIcon'
import CaffeineFreeIcon from '@/assets/svg/icons/CaffeineFreeIcon'
import CaffeineFreeTextIcon from '@/assets/svg/icons/CaffeineFreeTextIcon'
import CoffeeBeanIcon from '@/assets/svg/icons/CoffeeBeanIcon'
import CoffeeOriginIcon from '@/assets/svg/icons/CoffeeOriginIcon'
import CupWithSteamIcon from '@/assets/svg/icons/CupWithSteamIcon'
import DarqLogoClaimIcon from '@/assets/svg/icons/DarqLogoClaimIcon'
import DarqLogoIcon from '@/assets/svg/icons/DarqLogoIcon'
import DarqLogoMainIcon from '@/assets/svg/icons/DarqLogoMainIcon'
import DarqLogoSignetIcon from '@/assets/svg/icons/DarqLogoSignetIcon'
import DarqNuoIcon from '@/assets/svg/icons/DarqNuoIcon'
import DiamondIcon from '@/assets/svg/icons/DiamondIcon'
import DiamondInsertIcon from '@/assets/svg/icons/DiamondInsertIcon'
import DiamondOpenIcon from '@/assets/svg/icons/DiamondOpenIcon'
import DiamondRemovalIcon from '@/assets/svg/icons/DiamondRemovalIcon'
import HexagonFilledIcon from '@/assets/svg/icons/HexagonFilledIcon'
import HexagonIcon from '@/assets/svg/icons/HexagonIcon'
import HomeCompostIcon from '@/assets/svg/icons/HomeCompostIcon'
import HomeCompostTextIcon from '@/assets/svg/icons/HomeCompostTextIcon'
import MailIcon from '@/assets/svg/icons/MailIcon'
import NegativeAttentionIcon from '@/assets/svg/icons/NegativeAttentionIcon'
import NewsletterIcon from '@/assets/svg/icons/NewsletterIcon'
import UserAccountIcon from '@/assets/svg/icons/UserAccountIcon'
import WishlistAddIcon from '@/assets/svg/icons/WishlistAddIcon'
import WishlistIcon from '@/assets/svg/icons/WishlistIcon'
import ThermalMugIcon from '@/assets/svg/icons/ThermalMugIcon'

export const PuroIcons = {
  Abo: AboIcon,
  AboText: AboTextIcon,
  Barcode: BarcodeIcon,
  Basket: BasketIcon,
  BrewingProcess: BrewingProcessIcon,
  CaffeineFree: CaffeineFreeIcon,
  CaffeineFreeText: CaffeineFreeTextIcon,
  CoffeeBean: CoffeeBeanIcon,
  CoffeeOrigin: CoffeeOriginIcon,
  CupWithSteam: CupWithSteamIcon,
  DarqLogo: DarqLogoIcon,
  DarqLogoClaim: DarqLogoClaimIcon,
  DarqLogoMain: DarqLogoMainIcon,
  DarqLogoSignet: DarqLogoSignetIcon,
  DarqNuo: DarqNuoIcon,
  Diamond: DiamondIcon,
  DiamondInsert: DiamondInsertIcon,
  DiamondOpen: DiamondOpenIcon,
  DiamondRemoval: DiamondRemovalIcon,
  Hexagon: HexagonIcon,
  HexagonFilled: HexagonFilledIcon,
  HomeCompost: HomeCompostIcon,
  HomeCompostText: HomeCompostTextIcon,
  Mail: MailIcon,
  NegativeAttention: NegativeAttentionIcon,
  Newsletter: NewsletterIcon,
  ThermalMug: ThermalMugIcon,
  UserAccount: UserAccountIcon,
  Wishlist: WishlistIcon,
  WishlistAdd: WishlistAddIcon
}

// Mapping
const PuroSVGMapping = {
  ...SVGMapping,
  ...PuroIcons
}

export default PuroSVGMapping
