import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const DiamondInsertIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M1.5,2.94L12,15.94L22.5,2.94H1.5z M8.31,3.94h7.36L12,13.03L8.31,3.94z M16.74,3.94h3.66l-7.32,9.07L16.74,3.94z
		 M7.23,3.94L10.91,13L3.59,3.94H7.23z'
        />
        <g>
          <polygon points='11.97,22.17 15.12,20.33 15.12,19.17 11.97,21.01 8.82,19.17 8.82,20.32 		' />
          <polygon points='8.82,17.29 11.97,19.13 15.12,17.3 15.12,16.14 11.97,17.98 8.82,16.14 		' />
        </g>
      </g>
    </svg>
  )
}

export default DiamondInsertIcon
