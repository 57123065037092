import React from 'react'
import { useIntl } from 'react-intl'
import { Footer, FooterLogo } from 'msp-components'
import { Region, transformRegionToMap, useCookie } from 'msp-integrations'
import {
  FirstContentSlot,
  SecondContentSlot,
  ThirdContentSlot,
  FourthContentSlot,
  PreFooterMobileSlot,
  PostFooterMobileSlot
} from './PuroFooter.partials'

const PuroFooter = ({ regions }) => {
  const areas = transformRegionToMap(regions)
  const { setCookieModal } = useCookie()
  const { formatMessage } = useIntl()

  return (
    <Footer
      logoComponent={
        <FooterLogo iconName='DarqLogo' iconWidth='250px' title='Darq' />
      }
      preFooterMobileSlot={<PreFooterMobileSlot />}
      firstColumnSlot={
        <FirstContentSlot>
          <Region
            as='ul'
            noWrapper
            region={areas.get('column1')}
            eventName='footer_tracking'
          />
        </FirstContentSlot>
      }
      secondColumnSlot={
        <SecondContentSlot>
          <Region
            noWrapper
            region={areas.get('column2')}
            eventName='footer_tracking'
          />
        </SecondContentSlot>
      }
      thirdColumnSlot={
        <ThirdContentSlot>
          <Region
            noWrapper
            region={areas.get('column3')}
            eventName='footer_tracking'
          />
        </ThirdContentSlot>
      }
      fourthColumnSlot={
        <FourthContentSlot>
          <Region
            noWrapper
            region={areas.get('column4')}
            eventName='footer_tracking'
          />
        </FourthContentSlot>
      }
      postFooterMobileSlot={<PostFooterMobileSlot />}
      navFooterSlot={
        <div className='flex flex-col md:flex-row'>
          <Region
            className='flex flex-row flex-wrap justify-center py-4 md:text-center lg:py-0'
            as='ul'
            region={areas.get('navfooter')}
            eventName='footer_tracking'
          />
          <div
            className='mt-1 flex cursor-pointer items-center justify-center px-4 md:mt-0 md:items-start'
            onClick={() => setCookieModal(true, true)}
          >
            {formatMessage({
              defaultMessage: 'Cookies',
              id: 'footer.cookies_modal_button'
            })}
          </div>
        </div>
      }
    />
  )
}

export default PuroFooter
