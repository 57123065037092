import React, { useState, useEffect } from 'react'
import { ScrollTranstionWrapper, Carousel, Headline } from 'msp-components'
import {
  useWindowSize,
  findImageGroupBy,
  configureDISBaseLink,
  ProductImage
} from 'msp-integrations'
import useProduct from '@/hooks/use-product'
import PuroCommerceBox from '@/components/molecules/PuroCommerceBox/PuroCommerceBox'
import ScrollForMore from '@/components/atoms/ScrollForMore/ScrollForMore'
import {
  MachineCommerceProduct,
  MachineCommerceStageProps
} from './MachineCommerceStage.types'

const textColor = {
  light: 'text-black',
  dark: 'text-white'
}

const bgColor = {
  light: 'bg-linenBackground',
  dark: 'bg-black'
}

const borderColor = {
  light: 'border-black',
  dark: 'border-white'
}

const MachineCommerceStage = ({
  className,
  title,
  headline,
  children,
  product1,
  product2
}: MachineCommerceStageProps) => {
  const { width, breakpoints } = useWindowSize()
  const isDesktop = width && width >= breakpoints.lg
  const [isTransitionDone, setIsTransitionDone] = useState(false)
  const [variants, setVariants] = useState<{ [key: string]: string }>()
  const [products, setProducts] = useState<{
    [key: string]: MachineCommerceProduct
  }>(null)
  const [selectedProductId, setSelectedProductId] = useState<string>(
    product1.id
  )

  const productOne = useProduct(product1.id)
  const productTwo = useProduct(product2.id)

  const currentTheme = products?.[selectedProductId]?.theme || 'light'

  const formatVariantLabel = (label: string): string =>
    (label || '').replace(/-/g, ' ')

  const getProductImages = (
    product: {
      imageGroups: []
      variationValues?: object
      name?: string
    },
    productImageUrl: string,
    imageAlt?: string
  ): ProductImage[] => {
    if (product.imageGroups?.length > 0) {
      const largeImageGroupProduct = findImageGroupBy(product.imageGroups, {
        viewType: 'large',
        selectedVariationAttributes: product?.variationValues || {}
      })

      const productGallery: ProductImage[] = largeImageGroupProduct?.images.map(
        (image: ProductImage) => {
          if (image.disBaseLink) {
            return {
              ...image,
              disBaseLink: configureDISBaseLink(image.disBaseLink, {
                scaleWidth: 1200
              })
            }
          }
          return image
        }
      )

      if (productImageUrl) {
        productGallery.unshift({
          alt: imageAlt || product.name,
          link: productImageUrl,
          disBaseLink: productImageUrl,
          title: product.name
        })
      }

      return productGallery
    }

    return []
  }

  const addProduct = (product: MachineCommerceProduct): void => {
    if (!products || !products[product.id]) {
      const productsTemp = { [product.id]: product }
      const productList = { ...products, ...productsTemp }
      setProducts(productList)
    }
  }

  const onTransition = () => {
    setIsTransitionDone(true)
  }

  const onVariantChange = (productId: string) => {
    setSelectedProductId(productId)
  }

  useEffect(() => {
    setSelectedProductId(product1.id)

    if (productOne?.id) {
      const product: MachineCommerceProduct = {
        id: product1.id,
        image: product1.image,
        imageMobile: product1.imageMobile,
        theme: product1.theme
      }

      if (productOne?.imageGroups?.length > 0) {
        product.images = getProductImages(
          productOne,
          product1.image,
          product1.imageAlt
        )
      }

      addProduct(product)
    }

    if (productOne?.variants?.length > 0) {
      const variants: { [key: string]: string } = {}
      productOne.variants.forEach((element) => {
        variants[element.productId] = formatVariantLabel(
          element.variationValues?.refinementColor
        )
      })
      setVariants(variants)
    }
  }, [productOne?.id])

  useEffect(() => {
    if (productTwo?.id) {
      const product: MachineCommerceProduct = {
        id: product2.id,
        image: product2.image,
        imageMobile: product2.imageMobile,
        theme: product2.theme
      }

      if (productTwo?.imageGroups?.length > 0) {
        product.images = getProductImages(
          productTwo,
          product2.image,
          product2.imageAlt
        )
      }

      addProduct(product)
    }
  }, [productTwo?.id])

  if (isDesktop === undefined || !products) return null

  if (isDesktop) {
    return (
      <div className={'relative'}>
        <ScrollTranstionWrapper
          startAnimationOn={'30% top'}
          leftContainerTransitionDuration={1.5}
          rightContainerTransitionDuration={1.5}
          className={'h-screen w-full md:h-screen md:max-h-[150vw]'}
          rightContainerWidthPercentage={30}
          rightContent={
            <div
              className={`flex h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden transition-colors duration-500 ${bgColor[currentTheme]}`}
            >
              <PuroCommerceBox
                onVariantChange={onVariantChange}
                updateOnVariantChange={true}
                isDarkMode={currentTheme === 'dark'}
                showTaxesInfo={true}
                data={{
                  productId: selectedProductId,
                  type: 'Stage'
                }}
              />
            </div>
          }
          onStartAnimation={onTransition}
        >
          <div
            className={`relative h-full w-full transition-colors duration-500 ${textColor[currentTheme]}`}
          >
            <div
              className={`flex h-full w-full items-center justify-items-center ${bgColor[currentTheme]}`}
            >
              {products[selectedProductId] && (
                <div className={'h-full w-full'}>
                  <Carousel
                    productImages={products[selectedProductId]?.images || []}
                    autoplay={false}
                    isFullScreenMode={true}
                    hideArrows={true}
                    showThumbnails={true}
                    hideControl={!isTransitionDone}
                    hideIndicators={!isTransitionDone}
                    isDarkMode={currentTheme === 'light'}
                  />
                </div>
              )}
              <div
                className={`absolute bottom-40 left-40 top-40 flex w-[40vw] min-w-[40vw] max-w-[40vw] select-none flex-col transition-opacity duration-500 ${
                  isTransitionDone ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <div className={'h-fit w-full flex-none pb-8'}>
                  {title && (
                    <div
                      className={'font-GoodPro text-44 tracking-1 font-medium'}
                    >
                      {title}
                    </div>
                  )}
                  {variants && (
                    <div className={'flex flex-row'}>
                      {Object.entries(variants)?.map(([key, variant]) => {
                        const isSelected =
                          selectedProductId && key === selectedProductId
                        return (
                          <div
                            key={`variant_switch_btn_${key}`}
                            onClick={() => onVariantChange(key)}
                            className={`font-GoodPro text-14 tracking-0.4 cursor-pointer -space-x-0.5 border px-8 py-3 capitalize underline-offset-2 transition-all duration-500 hover:underline
                        ${borderColor[currentTheme]} ${
                              textColor[currentTheme]
                            } ${
                              isSelected
                                ? `underline opacity-100 ${bgColor[currentTheme]}`
                                : 'opacity-50'
                            }`}
                          >
                            {variant}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
                <div
                  className={
                    'font-GoodPro mb-52 flex h-full flex-col justify-center space-y-10'
                  }
                >
                  <Headline
                    tag='h2'
                    className='text-60 tracking-4 mb-6 font-normal uppercase'
                  >
                    {' '}
                    {headline}
                  </Headline>
                  <div className={'text-16 tracking-0.6'}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </ScrollTranstionWrapper>
        <div
          className={`absolute bottom-0 left-1/2 -translate-x-1/2 select-none transition-all duration-500 ${
            isTransitionDone ? 'invisible opacity-0' : 'visible opacity-100'
          }`}
        >
          <ScrollForMore isDarkMode={currentTheme === 'light'} />
        </div>
      </div>
    )
  } else {
    const productImage =
      products[selectedProductId]?.imageMobile ||
      products[selectedProductId]?.images[0]?.disBaseLink
    return (
      <div
        className={`relative mb-8 flex h-screen w-full flex-col transition-colors duration-500 ${textColor[currentTheme]}`}
      >
        <div className={'h-full'}>
          {productImage && (
            <img
              className={
                'h-[75vh] min-h-full w-full object-cover object-center'
              }
              src={productImage}
              alt={product1.imageAlt || productOne.name}
            />
          )}
        </div>
        <div className={`h-fit w-full flex-none bg-black pb-6`}>
          <PuroCommerceBox
            onVariantChange={onVariantChange}
            updateOnVariantChange={true}
            isDarkMode={true}
            showTaxesInfo={true}
            isMobile={true}
            data={{
              productId: selectedProductId,
              type: 'Stage'
            }}
          />
        </div>
        <div className={'absolute inset-x-8 top-16 h-2/3'}>
          <div className={'h-fit w-full flex-none pb-4'}>
            {title && (
              <div className={'font-GoodPro text-28 tracking-0.6 font-medium'}>
                {title}
              </div>
            )}
            {variants && (
              <div className={'flex flex-row'}>
                {Object.entries(variants)?.map(([key, variant]) => {
                  const isSelected =
                    selectedProductId && key === selectedProductId
                  return (
                    <div
                      key={`variant_switch_btn_${key}`}
                      onClick={() => onVariantChange(key)}
                      className={`font-GoodPro text-14 tracking-0.4 cursor-pointer -space-x-0.5 border px-8 py-3 capitalize underline-offset-2 transition-all duration-500 hover:underline
                        ${borderColor[currentTheme]} ${
                        textColor[currentTheme]
                      } ${
                        isSelected
                          ? `underline opacity-100 ${bgColor[currentTheme]}`
                          : 'opacity-50'
                      }`}
                    >
                      {variant}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={'font-GoodPro text-36 tracking-1.6 uppercase'}>
            {headline}
          </div>
        </div>
      </div>
    )
  }
}

export default MachineCommerceStage
