import React, { useState } from 'react'
import { Transition } from '@headlessui/react'
import { DynamicTag, transformRegionToMap, Grid } from 'msp-components'
import { ImageTile, GetActiveTileInfo } from './Discovery.partials'
import { DiscoveryProps } from './Discovery.types'
import * as S from './Discovery.styles'

const Discovery = ({ regions = [] }: DiscoveryProps) => {
  const [mainHeadline] =
    transformRegionToMap(regions)?.get('mainHeadline')?.components || []
  const regionsNames = [
    'discoveryItem1',
    'discoveryItem2',
    'discoveryItem3',
    'discoveryItem4'
  ]
  const discoveryItemsRegions = regionsNames.map(
    (regionName: string, index: number) => ({
      itemInfo:
        transformRegionToMap(regions)?.get(regionName)?.components || [],
      showState: index === 0 ? true : false
    })
  )
  const [activeTile, setActiveTile] = useState(discoveryItemsRegions[0])
  const [, , imageData] = activeTile.itemInfo || []
  return (
    <div className={S.discoveryWrapper}>
      {mainHeadline.data?.children && (
        <div className={S.overarchingHeadline}>
          <DynamicTag
            componentTagName='Headline'
            colorScheme='whiteAlpha'
            tag='h3'
          >
            {mainHeadline.data?.children}
          </DynamicTag>
        </div>
      )}
      <div data-testid={'discoverGrid'} className={`${S.gridWrapper} flex-row`}>
        <Grid wrapperStyles={S.gridStyles}>
          {discoveryItemsRegions.map((row: any) => (
            <div key={row.itemInfo?.[0]?.id}>
              <ImageTile
                activeTile={activeTile}
                discoveryItemsRegions={row}
                setActiveTile={setActiveTile}
                activeImageId={imageData?.id}
              />
              <div className='block lg:hidden'>
                <Transition
                  show={
                    imageData?.id === row.itemInfo[2]?.id
                      ? activeTile.showState
                      : false
                  }
                  enter='transition duration-500 ease-in-out transform'
                  enterFrom='transform scale-95 opacity-0'
                  enterTo='transform scale-100 opacity-100'
                  leave='transition duration-400 ease-in-out transform'
                  leaveFrom='transform scale-100 opacity-100'
                  leaveTo='transform scale-95 opacity-0'
                >
                  <GetActiveTileInfo activeTileInfo={row} />
                </Transition>
              </div>
            </div>
          ))}
        </Grid>
        <div className='hidden lg:block lg:w-[40%]'>
          <GetActiveTileInfo
            activeTileInfo={activeTile}
            wrapperId={'desktopView'}
          />
        </div>
      </div>
      <div className='clear-both' />
    </div>
  )
}

export default Discovery
