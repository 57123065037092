import React from 'react'
import { IconsProps } from 'msp-integrations'

const NegativeAttentionIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path
        fill='#808080'
        d='M70.87,36.43c-19.02,0-34.43,15.42-34.43,34.43c0,19.02,15.42,34.43,34.43,34.43
	c19.02,0,34.43-15.42,34.43-34.43C105.3,51.85,89.88,36.43,70.87,36.43z M66.34,52.16c0-4.02,4.02-4.02,4.02-4.02h5.04V71.5
	c0,4.02-4.02,4.02-4.02,4.02h-5.04V52.16z M70.87,92.2c-3.15,0-5.71-2.56-5.71-5.71c0-3.15,2.56-5.71,5.71-5.71
	c3.15,0,5.71,2.56,5.71,5.71C76.58,89.64,74.02,92.2,70.87,92.2z'
      />
    </svg>
  )
}

export default NegativeAttentionIcon
