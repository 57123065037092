import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const HexagonIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path d='M12,22.08l-8.88-5.03V6.96L12,1.92l8.88,5.04v10.08L12,22.08z M4.12,16.46L12,20.92l7.88-4.47V7.54L12,3.08L4.12,7.54 V16.46z' />
        </g>
      </g>
    </svg>
  )
}

export default HexagonIcon
