import React from 'react'
import { IconsProps } from 'msp-integrations'

const UserAccountIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <rect x='159.14' y='173.43' fill='#FDEF6' width='24' height='24' />
        <rect x='161.14' y='175.43' fill='#FFFFFF' width='20' height='20' />
        <g>
          <path
            fill='#1D1D1B'
            d='M168.21,180.6c-1.99,1.15-2.68,3.32-1.62,5.17l1.92,3.33l0,0c0.68,1.17,1.83,1.79,3.09,1.79
			c0.71,0,1.46-0.2,2.18-0.62c1.99-1.15,2.68-3.32,1.62-5.17l-1.92-3.33C172.42,179.95,170.2,179.45,168.21,180.6z M173.28,189.41
			c-1.51,0.88-3.12,0.54-3.91-0.81l-1.92-3.32c-0.78-1.36-0.26-2.93,1.26-3.8c1.51-0.87,3.12-0.54,3.91,0.81l1.92,3.33
			C175.31,186.97,174.8,188.53,173.28,189.41z'
          />
          <path
            fill='#1D1D1B'
            d='M171.19,184.98c-0.62-0.26-1.17-1.26-1.17-2.14l-1,0c0.01,1.3,0.79,2.64,1.79,3.06
			c0.62,0.26,1.17,1.26,1.17,2.14l1,0C172.97,186.74,172.18,185.39,171.19,184.98'
          />
          <path
            fill='#1D1D1B'
            d='M175.43,193.07c-2.04,1.18-4.42,1.49-6.69,0.88c-2.28-0.61-4.18-2.07-5.36-4.11
			c-1.18-2.04-1.49-4.42-0.88-6.69c0.61-2.28,2.07-4.18,4.11-5.36c2.04-1.18,4.42-1.49,6.69-0.88c2.1,0.56,3.87,1.85,5.05,3.64
			h-2.44l0.58,1h3.07l1.53-2.7l-0.58-1.01l-1.27,2.24c-1.32-2.04-3.32-3.5-5.68-4.14c-2.54-0.68-5.18-0.33-7.45,0.98
			c-2.27,1.31-3.9,3.43-4.58,5.96c-0.68,2.53-0.33,5.18,0.98,7.45c1.31,2.27,3.43,3.9,5.96,4.58c0.85,0.23,1.7,0.34,2.56,0.34
			c1.7,0,3.38-0.45,4.9-1.32c2.15-1.24,3.71-3.21,4.45-5.56h-1.05C178.63,190.36,177.27,192.01,175.43,193.07z'
          />
        </g>
      </g>
      <path d='m 14.715656,13.411554 c 1.69329,-0.997046 2.8541,-2.890327 2.8541,-5.0748793 C 17.569756,5.1214626 15.077742,2.5 12.021299,2.5 8.9648562,2.5 6.4834931,5.1326649 6.4834931,8.3478777 c 0,2.1845523 1.1501598,4.0666303 2.8434509,5.0636763 C 5.3652822,14.363797 2.543131,17.422174 2,21.5 h 1.0756123 c 0.6602769,-4.290688 4.2066028,-7.281834 8.9137377,-7.281834 4.728434,0 8.28541,2.991146 8.935038,7.281834 H 22 C 21.478169,17.433376 18.666667,14.375 14.715656,13.411554 Z M 7.5484559,8.3478777 c 0,-2.5990565 2.0127801,-4.7163918 4.4834931,-4.7163918 2.470713,0 4.483493,2.1173353 4.483493,4.7163918 0,2.5990603 -2.01278,4.7163883 -4.483493,4.7163883 -2.470713,0 -4.4834931,-2.117328 -4.4834931,-4.7163883 z' />
    </svg>
  )
}

export default UserAccountIcon
