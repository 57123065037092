import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const HomeCompostTextIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path d='M0.38,17.61v1.25h1.09v-1.25h0.38v2.88H1.48v-1.3H0.38v1.3H0v-2.88H0.38z' />
          <path
            d='M3.95,19.82c0,0.41-0.27,0.71-0.78,0.71s-0.78-0.3-0.78-0.71v-0.65c0-0.41,0.27-0.72,0.78-0.72c0.51,0,0.78,0.3,0.78,0.72
			V19.82z M2.74,19.81c0,0.28,0.14,0.46,0.43,0.46c0.3,0,0.44-0.18,0.44-0.46v-0.62c0-0.29-0.14-0.47-0.44-0.47
			s-0.43,0.18-0.43,0.47V19.81z'
          />
          <path
            d='M5.85,20.5H5.51v-1.3c0-0.34-0.14-0.46-0.34-0.46c-0.17,0-0.32,0.12-0.39,0.28v1.48H4.43v-2.01h0.3v0.23h0.01
			c0.12-0.18,0.31-0.27,0.52-0.27c0.22,0,0.4,0.1,0.49,0.32h0.01c0.11-0.2,0.31-0.32,0.55-0.32c0.39,0,0.62,0.24,0.62,0.74v1.3H6.59
			v-1.3c0-0.29-0.11-0.46-0.36-0.46c-0.21,0-0.38,0.14-0.38,0.45V20.5z'
          />
          <path
            d='M7.71,19.51v0.29c0,0.3,0.16,0.47,0.45,0.47c0.21,0,0.35-0.1,0.45-0.33l0.25,0.09c-0.12,0.34-0.35,0.51-0.72,0.51
			c-0.49,0-0.76-0.3-0.76-0.72v-0.64c0-0.41,0.27-0.72,0.74-0.72c0.47,0,0.73,0.32,0.73,0.73v0.33H7.71z M7.71,19.3h0.83v-0.15
			c0-0.27-0.15-0.43-0.42-0.43c-0.28,0-0.41,0.17-0.41,0.44V19.3z'
          />
          <path
            d='M11.97,18.17l-0.28,0.11c-0.07-0.24-0.25-0.39-0.53-0.39c-0.37,0-0.56,0.24-0.56,0.66v1.03c0,0.38,0.19,0.65,0.56,0.65
			c0.29,0,0.45-0.11,0.53-0.42l0.28,0.1c-0.1,0.43-0.39,0.63-0.84,0.63c-0.56,0-0.92-0.39-0.92-0.96v-1.03
			c0-0.61,0.35-0.98,0.92-0.98C11.56,17.57,11.88,17.84,11.97,18.17z'
          />
          <path
            d='M13.89,19.82c0,0.41-0.27,0.71-0.78,0.71c-0.51,0-0.78-0.3-0.78-0.71v-0.65c0-0.41,0.27-0.72,0.78-0.72
			c0.51,0,0.78,0.3,0.78,0.72V19.82z M12.69,19.81c0,0.28,0.14,0.46,0.43,0.46c0.3,0,0.44-0.18,0.44-0.46v-0.62
			c0-0.29-0.14-0.47-0.44-0.47s-0.43,0.18-0.43,0.47V19.81z'
          />
          <path
            d='M15.8,20.5h-0.34v-1.3c0-0.34-0.14-0.46-0.34-0.46c-0.17,0-0.32,0.12-0.39,0.28v1.48h-0.34v-2.01h0.3v0.23h0.01
			c0.12-0.18,0.31-0.27,0.52-0.27c0.22,0,0.4,0.1,0.49,0.32h0.01c0.11-0.2,0.31-0.32,0.55-0.32c0.39,0,0.62,0.24,0.62,0.74v1.3
			h-0.34v-1.3c0-0.29-0.11-0.46-0.36-0.46c-0.21,0-0.38,0.14-0.38,0.45V20.5z'
          />
          <path
            d='M17.75,20.32L17.75,20.32l-0.01,0.85H17.4v-2.68h0.3l0.01,0.21h0.01c0.14-0.16,0.33-0.25,0.58-0.25
			c0.43,0,0.65,0.29,0.65,0.74v0.59c0,0.45-0.23,0.74-0.67,0.74C18.06,20.53,17.88,20.46,17.75,20.32z M17.74,20.03
			c0.09,0.12,0.23,0.22,0.45,0.22c0.28,0,0.41-0.17,0.41-0.47V19.2c0-0.29-0.13-0.46-0.41-0.46c-0.21,0-0.37,0.1-0.46,0.23V20.03z'
          />
          <path
            d='M20.9,19.82c0,0.41-0.27,0.71-0.78,0.71c-0.51,0-0.78-0.3-0.78-0.71v-0.65c0-0.41,0.27-0.72,0.78-0.72
			c0.51,0,0.78,0.3,0.78,0.72V19.82z M19.69,19.81c0,0.28,0.14,0.46,0.43,0.46c0.3,0,0.44-0.18,0.44-0.46v-0.62
			c0-0.29-0.14-0.47-0.44-0.47c-0.3,0-0.43,0.18-0.43,0.47V19.81z'
          />
          <path
            d='M22.39,18.97c-0.08-0.19-0.23-0.27-0.42-0.27c-0.23,0-0.34,0.12-0.34,0.28c0,0.44,1.01,0.27,1.01,0.96
			c0,0.35-0.27,0.59-0.66,0.59c-0.32,0-0.56-0.14-0.7-0.44l0.22-0.11c0.1,0.22,0.28,0.31,0.47,0.31c0.23,0,0.35-0.12,0.35-0.32
			c0-0.45-1.01-0.29-1.01-0.96c0-0.3,0.23-0.56,0.66-0.56c0.31,0,0.54,0.15,0.65,0.43L22.39,18.97z'
          />
          <path
            d='M24,18.75h-0.5v1.21c0,0.22,0.11,0.31,0.27,0.31c0.07,0,0.13-0.01,0.18-0.04L24,20.44c-0.09,0.05-0.2,0.09-0.34,0.09
			c-0.35,0-0.5-0.21-0.5-0.58v-1.18l-0.26-0.06V18.5l0.28-0.02l0.09-0.57h0.24v0.58H24V18.75z'
          />
        </g>
        <path
          d='M12.28,7.61V6.13c0.35,0.35,0.83,0.56,1.36,0.56c1.06,0,1.92-0.86,1.93-1.92l0-1.94l-1.94,0
		c-0.78,0.01-1.45,0.48-1.75,1.15c-0.3-0.34-0.73-0.56-1.21-0.56l-1.64,0l0,1.64c0.01,0.89,0.74,1.62,1.63,1.62
		c0.41,0,0.77-0.15,1.06-0.4v1.32H6.04L12,15l5.96-7.39H12.28z M13.63,3.4l1.37,0l0,1.37c0,0.75-0.62,1.35-1.36,1.35
		c-0.28,0-0.53-0.08-0.75-0.23l1.08-1.08l-0.4-0.4l-1.08,1.08c-0.13-0.21-0.22-0.46-0.22-0.73C12.28,4.02,12.88,3.41,13.63,3.4z
		 M11.57,5.59l-0.8-0.8l-0.4,0.4l0.8,0.8c-0.15,0.08-0.32,0.13-0.51,0.13c-0.58,0-1.06-0.47-1.06-1.05l0-1.07l1.07,0
		c0.58,0,1.05,0.48,1.05,1.06C11.72,5.25,11.66,5.43,11.57,5.59z M7.22,8.18h2.07l2.09,5.15L7.22,8.18z M9.91,8.18h4.18L12,13.34
		L9.91,8.18z M14.7,8.18h2.08l-4.16,5.15L14.7,8.18z'
        />
      </g>
    </svg>
  )
}

export default HomeCompostTextIcon
