import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const CoffeeOriginIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <rect x='159.14' y='173.43' fill='#FDEEF6' width='24' height='24' />
        <rect x='161.14' y='175.43' fill='#FFFFFF' width='20' height='20' />
        <g>
          <path
            fill='#1D1D1B'
            d='M168.21,180.6c-1.99,1.15-2.68,3.32-1.62,5.17l1.92,3.33l0,0c0.68,1.17,1.83,1.79,3.09,1.79
			c0.71,0,1.46-0.2,2.18-0.62c1.99-1.15,2.68-3.32,1.62-5.17l-1.92-3.33C172.42,179.95,170.2,179.45,168.21,180.6z M173.28,189.41
			c-1.51,0.88-3.12,0.54-3.91-0.81l-1.92-3.32c-0.78-1.36-0.26-2.93,1.26-3.8c1.51-0.87,3.12-0.54,3.91,0.81l1.92,3.33
			C175.31,186.97,174.8,188.53,173.28,189.41z'
          />
          <path
            fill='#1D1D1B'
            d='M171.19,184.98c-0.62-0.26-1.17-1.26-1.17-2.14l-1,0c0.01,1.3,0.79,2.64,1.79,3.06
			c0.62,0.26,1.17,1.26,1.17,2.14l1,0C172.97,186.74,172.18,185.39,171.19,184.98'
          />
          <path
            fill='#1D1D1B'
            d='M175.43,193.07c-2.04,1.18-4.42,1.49-6.69,0.88c-2.28-0.61-4.18-2.07-5.36-4.11
			c-1.18-2.04-1.49-4.42-0.88-6.69c0.61-2.28,2.07-4.18,4.11-5.36c2.04-1.18,4.42-1.49,6.69-0.88c2.1,0.56,3.87,1.85,5.05,3.64
			h-2.44l0.58,1h3.07l1.53-2.7l-0.58-1.01l-1.27,2.24c-1.32-2.04-3.32-3.5-5.68-4.14c-2.54-0.68-5.18-0.33-7.45,0.98
			c-2.27,1.31-3.9,3.43-4.58,5.96c-0.68,2.53-0.33,5.18,0.98,7.45c1.31,2.27,3.43,3.9,5.96,4.58c0.85,0.23,1.7,0.34,2.56,0.34
			c1.7,0,3.38-0.45,4.9-1.32c2.15-1.24,3.71-3.21,4.45-5.56h-1.05C178.63,190.36,177.27,192.01,175.43,193.07z'
          />
        </g>
      </g>
      <g>
        <path
          d='M14.64,5.72c-1.89-1.09-4-0.62-5.02,1.12L7.81,9.99c-1,1.75-0.35,3.82,1.55,4.91c0.68,0.39,1.4,0.59,2.07,0.59
		c1.2,0,2.29-0.59,2.94-1.71l1.82-3.15C17.19,8.89,16.54,6.82,14.64,5.72z M15.32,10.14l-1.81,3.14c-0.74,1.26-2.24,1.58-3.65,0.76
		c-1.42-0.82-1.9-2.28-1.18-3.55l1.81-3.14c0.46-0.79,1.23-1.21,2.08-1.21c0.51,0,1.05,0.15,1.57,0.45
		C15.56,7.41,16.05,8.87,15.32,10.14z'
        />
        <path
          d='M12.19,10.78c0.95-0.4,1.7-1.68,1.71-2.91l-1,0c0,0.82-0.51,1.75-1.09,2c-0.95,0.4-1.7,1.68-1.71,2.92l1,0
		C11.1,11.95,11.61,11.02,12.19,10.78'
        />
        <path
          d='M17.73,4.58c-3.16-3.16-8.31-3.16-11.47,0c-3.14,3.14-3.16,8.27-0.03,11.43L12,21.79l5.77-5.78
		C20.89,12.85,20.87,7.72,17.73,4.58z M17.06,15.3L12,20.38l-5.06-5.07C4.21,12.53,4.22,8.04,6.97,5.29C8.36,3.9,10.18,3.21,12,3.21
		c1.82,0,3.64,0.69,5.03,2.08C19.78,8.04,19.79,12.53,17.06,15.3z'
        />
      </g>
    </svg>
  )
}

export default CoffeeOriginIcon
