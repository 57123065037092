import React from 'react'
import { ImageText, Table } from 'msp-components'
import { Region } from 'msp-integrations'
import { ProductDetailsProps } from './PuroProductDetails.types'
import {
  getCoffeeProperties,
  getIngredientsAndAllergens
} from './PuroProductDetails.utils'

const PuroProductDetails = ({
  product: {
    name,
    c_caffeine,
    c_specialties,
    c_aromatisation,
    c_coffeeOrigin,
    c_mountingHeight,
    c_ingredients,
    c_allergens,
    c_coffeeDescription2Headline,
    c_coffeeDescription2,
    c_taste,
    c_sorte
  },
  imagePosition = 'right',
  bgStyle = 'light',
  imageSrc = '',
  imageAlt,
  content
}: ProductDetailsProps) => {
  const coffeeProperties = getCoffeeProperties({
    c_caffeine,
    c_specialties,
    c_aromatisation,
    c_coffeeOrigin,
    c_mountingHeight,
    c_taste,
    c_sorte
  })

  const ingredientsAndAllergens = getIngredientsAndAllergens({
    c_ingredients,
    c_allergens
  })

  return (
    <ImageText
      background={bgStyle}
      imagePosition={imagePosition}
      imageUrl={imageSrc}
      imageAlt={imageAlt || `${name} Details`}
    >
      <div className='mx-auto flex min-w-[100%] max-w-xl flex-col'>
        <div className='mb-14'>
          {!!c_coffeeDescription2Headline && (
            <h2 className='font-GoodPro headline-h3 mb-4 block text-left md:mb-6'>
              {c_coffeeDescription2Headline}
            </h2>
          )}
          {!!c_coffeeDescription2 && (
            <span
              className='font-GoodPro text-16 tracking-[0.6px]'
              dangerouslySetInnerHTML={{ __html: c_coffeeDescription2 }}
            />
          )}
        </div>
        {content?.components && (
          <div className='mb-14 h-fit w-full'>
            <Region region={content} />
          </div>
        )}
        {coffeeProperties.length > 0 && (
          <Table title='Properties' fields={coffeeProperties} />
        )}
        {ingredientsAndAllergens.length > 0 && (
          <div className='mt-10 md:mt-0'>
            <Table
              title='Ingredients / Allergens'
              fields={ingredientsAndAllergens}
            />
          </div>
        )}
      </div>
    </ImageText>
  )
}
export default PuroProductDetails
