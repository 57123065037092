import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  useBasket,
  useConfig,
  useCookie,
  getEventItemFromProduct
} from 'msp-integrations'
import {
  AlertBox,
  ButtonLabelProps,
  ButtonTypeProps,
  BrandCommerceBoxProps,
  CommerceBox,
  CommerceBoxAnchorLink,
  CommerceBoxProductProps,
  EventsEmitter,
  Events,
  Icons,
  Button,
  WishlistButton,
  PromoCode
} from 'msp-components'
import useProduct from '@/hooks/use-product'
import {
  validateProduct,
  dispatchChoiceOfBonusModal
} from './PuroCommerceBox.helpers'
import * as S from './PuroCommerceBox.styles'

const PuroCommerceBox = ({
  data,
  onAddToCart,
  onQuantityChange,
  onVariantChange,
  onDelete,
  noPadding,
  smallDiscountPrice = false,
  showTaxesInfo = false,
  noVariants = false,
  isPDP = false,
  updateOnVariantChange = false,
  isDarkMode = false,
  isMobile = false,
  isWishlistPage = false,
  onWishlistChange,
  showWishListButton = true,
  showLowestPrice = false,
  handleAddToCart
}: BrandCommerceBoxProps) => {
  const {
    type,
    productId,
    product,
    showAnchorLink = false,
    addButtonAsIcon
  } = data
  const { eventTracking } = useCookie()
  const { formatMessage } = useIntl()
  const [productSelected, setProductSelected] = useState<
    CommerceBoxProductProps | string | undefined
  >(productId || product)
  const parsedProduct = useProduct(
    validateProduct(product || productSelected, noVariants)
  )

  const [isVirtualGiftCard, setIsVirtualGiftCard] = useState(true)
  const basket = useBasket()
  const { showLoading } = parsedProduct || { showLoading: true } // If product data is provided by parent
  const alertBoxRef = useRef<AlertBox>()
  const addToCartButtonRef = useRef()
  const scrollIntoViewElementRef = useRef()
  const [handleAddToCartError, setHandleAddToCartError] = useState<{
    [k: string]: any
  }>({})
  const CTAButton = S.BUTTON_TYPES[addButtonAsIcon || type]

  const titleCantAddProduct = formatMessage({
    id: 'msp.commerce_box.add_to_cart.cannot_add_title',
    defaultMessage: 'Cannot add product to basket'
  })
  const descriptionCantAddProduct = formatMessage({
    id: 'msp.commerce_box.add_to_cart.cannot_add_physical_product',
    defaultMessage:
      'Physical products cannot be added to the basket if a digital product has already been selected.'
  })
  const titleNoOrderableProduct = formatMessage({
    id: 'msp.commerce_box.add_to_cart.not_orderable_item.title',
    defaultMessage: 'Item(s) could not be added to the basket'
  })
  const descriptionNoOrderableProduct = formatMessage({
    id: 'msp.commerce_box.add_to_cart.not_orderable_item.message',
    defaultMessage:
      'At least one item from this set is not orderable at the moment and could not be added to the basket.'
  })

  const {
    theme: {
      mspComponents: { wishlistButton }
    }
  } = useConfig()

  const onAddToCartHandler = (
    quantity: number,
    basket: any,
    parsedProduct: CommerceBoxProductProps,
    onAddToCart?: (quantity: number) => void
  ) => {
    const productIsGiftCard = parsedProduct.c_isGiftcard || false
    const containsNormalProducts =
      (basket.productItems || []).length > 0 && !basket.c_containsVGC

    if (productIsGiftCard) {
      if (
        (basket.c_containsOnlyNormalGC || containsNormalProducts) &&
        isVirtualGiftCard
      ) {
        setHandleAddToCartError({
          title: titleCantAddProduct,
          errorCode: 'cannot_add_virtual_giftcard',
          alertMessage: formatMessage({
            id: 'msp.commerce_box.add_to_cart.cannot_add_virtual_giftcard',
            defaultMessage:
              'Virtual giftcards cannot be added to the basket if a physical product has already been selected.'
          }),
          isError: true
        })
        alertBoxRef?.current?.showAlert()
        return
      } else if (!isVirtualGiftCard && basket.c_containsVGC) {
        setHandleAddToCartError({
          title: titleCantAddProduct,
          errorCode: 'cannot_add_physical_product',
          alertMessage: descriptionCantAddProduct,
          isError: true
        })
        alertBoxRef?.current?.showAlert()
        return
      }
    } else {
      if (basket.c_containsVGC) {
        setHandleAddToCartError({
          title: titleCantAddProduct,
          errorCode: 'cannot_add_physical_product',
          alertMessage: descriptionCantAddProduct,
          isError: true
        })
        alertBoxRef?.current?.showAlert()
        return
      }
    }

    // WARNING: This function is call wether the product is already in the basket or not.
    // This is possible because a configuration that is done in SaleForce 'Baskets'.
    // However a review with René will be neccesary.
    if (onAddToCart) {
      onAddToCart(quantity)
    }

    const items = []
    const eventTrackingItems: any[] = []
    let hasOutOfStockItems = false
    if (parsedProduct.c_setItemQuantities?.length > 0) {
      const products = parsedProduct.c_setItemQuantities.filter(
        (product: any) => product.productId
      )
      products.map(
        ({
          productId,
          quantity,
          orderable,
          price,
          productName,
          primaryCategoryId
        }: any) => {
          if (orderable) {
            items.push({
              productId,
              quantity,
              price: parsedProduct.price,
              c_productSet: parsedProduct.id
            })
            eventTrackingItems.push({
              item_id: productId,
              quantity,
              item_name: productName,
              item_category: primaryCategoryId,
              item_price: price
            })
          } else {
            setHandleAddToCartError({
              alertMessage: descriptionNoOrderableProduct,
              errorCode: 'non_orderable_product',
              isWarning: true,
              title: titleNoOrderableProduct
            })
            hasOutOfStockItems = true
          }
        }
      )
    } else {
      let item: any = {
        productId: parsedProduct.id,
        price: parsedProduct.price,
        quantity
      }

      if (productIsGiftCard) {
        item = {
          ...item,
          c_giftCardIsVirtual: isVirtualGiftCard
        }
      }

      items.push(item)
      eventTrackingItems.push(getEventItemFromProduct(parsedProduct))
    }

    basket.addItemToBasket(items).then((response: any) => {
      if (response?.isError) {
        const { statusCode = '', statusMessage = '' } = response.arguments

        setHandleAddToCartError({
          errorCode: statusCode.toLowerCase(),
          alertMessage: statusMessage,
          isError: true
        })
        alertBoxRef?.current?.showAlert()
      } else {
        eventTracking('add_to_cart', {
          ecommerce: {
            currency: parsedProduct?.currency,
            items: eventTrackingItems
          }
        })
        EventsEmitter.dispatch(Events.ADD_TO_CART_SUCCESS, null)
        dispatchChoiceOfBonusModal(response)

        if (hasOutOfStockItems) {
          if (alertBoxRef?.current?.showAlert() === undefined) {
            handleAddToCart({
              message: descriptionNoOrderableProduct,
              title: titleNoOrderableProduct,
              type: 'warning',
              position: 'center',
              showBackdrop: true
            })
          } else {
            alertBoxRef?.current?.showAlert()
          }
        }
      }
    })
  }

  const onCodeAdded = (hasChoiceOfBonusProducts: boolean) => {
    if (!hasChoiceOfBonusProducts) {
      EventsEmitter.dispatch(Events.ADD_TO_CART_SUCCESS, null)
    }
  }

  useEffect(() => {
    if (!product) setProductSelected(productId)
  }, [productId])

  return !showLoading && (parsedProduct as CommerceBoxProductProps) ? (
    <>
      <CommerceBox
        isPDP={isPDP}
        showTaxesInfo={showTaxesInfo}
        smallDiscountPrice={smallDiscountPrice}
        innerRef={scrollIntoViewElementRef}
        product={{
          ...parsedProduct,
          variants: noVariants ? [] : parsedProduct.variants,
          variant: noVariants ? null : parsedProduct.variant
        }}
        type={type}
        CTAButton={
          <Button
            innerRef={addToCartButtonRef}
            type={CTAButton.type as ButtonTypeProps['type']}
            variant={isDarkMode ? 'outline' : 'default'}
            iconName={CTAButton.icon}
            isDisabled={!parsedProduct.c_inventory?.orderable}
            className={`${isDarkMode ? 'border border-white' : ''} ${
              CTAButton.className
            }`}
            ariaLabel={formatMessage(CTAButton.ariaLabel as ButtonLabelProps)}
          >
            {CTAButton.label &&
              (
                formatMessage(CTAButton.label as ButtonLabelProps) || ''
              ).toUpperCase()}
          </Button>
        }
        WishlistButton={
          showWishListButton && (
            <WishlistButton
              product={parsedProduct}
              wishlistedContainerStyle={wishlistButton}
              isWishlistPage={isWishlistPage}
              onWishlistChange={onWishlistChange}
            />
          )
        }
        isDarkMode={isDarkMode}
        isMobile={isMobile}
        onAddToCart={(quantity: number) =>
          onAddToCartHandler(quantity, basket, parsedProduct, onAddToCart)
        }
        showLowestPrice={showLowestPrice}
        onQuantityChange={onQuantityChange}
        onDelete={onDelete}
        onVariantChange={(
          productId: string,
          giftCertIsVirtual: boolean = true
        ) => {
          if (onVariantChange) {
            onVariantChange(productId, giftCertIsVirtual)
            setIsVirtualGiftCard(giftCertIsVirtual)
            if (updateOnVariantChange) {
              setProductSelected(productId)
            }
          } else {
            setProductSelected(productId)
            setIsVirtualGiftCard(giftCertIsVirtual)
          }
        }}
        noPadding={noPadding}
        PromoCode={
          <PromoCode
            applyPromoCode={basket.applyPromoCode}
            bonusDiscountLineItems={basket?.bonusDiscountLineItems || []}
            onCodeAdded={onCodeAdded}
          />
        }
      />
      <AlertBox
        ref={alertBoxRef}
        title={handleAddToCartError?.title}
        description={handleAddToCartError.alertMessage}
        closeButtonIcon={<Icons iconName='Close' size='base' />}
        type={handleAddToCartError?.isWarning ? 'warning' : 'error'}
        autoCloseTime={4000}
        onClose={() => setHandleAddToCartError({})}
      />
      {showAnchorLink && (
        <CommerceBoxAnchorLink
          productType={(parsedProduct?.c_classCategoryID || '').toLowerCase()}
          CTAButtonRef={addToCartButtonRef}
          scrollTargetRef={scrollIntoViewElementRef}
          icon={<Icons iconName={'Basket'} size='2xl' />}
        />
      )}
    </>
  ) : (
    <></> // TODO: Spinners when the products details is being loaded
  )
}

export default PuroCommerceBox
