import React from 'react'
import { IconsProps } from 'msp-integrations'

const HomeCompostIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path
        d='M12.5,9.7V7.1c0.61,0.61,1.46,0.98,2.39,0.98c1.86,0,3.38-1.51,3.4-3.38l0-3.42l-3.42,0c-1.38,0.01-2.56,0.85-3.08,2.03
	c-0.52-0.59-1.28-0.98-2.13-0.98l-2.89,0l0,2.89c0.01,1.57,1.3,2.85,2.87,2.85c0.71,0,1.36-0.27,1.86-0.7V9.7h-10L12,22.71L22.5,9.7
	H12.5z M14.87,2.29l2.42,0l0,2.42c-0.01,1.31-1.08,2.38-2.4,2.38c-0.49,0-0.94-0.15-1.32-0.4l1.91-1.91l-0.71-0.71l-1.9,1.9
	c-0.24-0.37-0.38-0.81-0.38-1.28C12.49,3.38,13.56,2.3,14.87,2.29z M11.25,6.15L9.84,4.74L9.13,5.44l1.4,1.4
	c-0.27,0.15-0.57,0.24-0.89,0.24c-1.02,0-1.86-0.83-1.87-1.85l0-1.89l1.88,0c1.02,0.01,1.86,0.85,1.86,1.87
	C11.51,5.55,11.41,5.87,11.25,6.15z M3.59,10.7h3.64l3.68,9.07L3.59,10.7z M8.31,10.7h7.36L12,19.79L8.31,10.7z M16.75,10.7h3.66
	l-7.32,9.07L16.75,10.7z'
      />
    </svg>
  )
}

export default HomeCompostIcon
