export const CustomRecommendationWrapper =
  'flex flex-col max-w-[1354px] m-auto py-4 pl-4'

export const CustomRecommendationHeadWrapper =
  'flex items-center justify-between pb-4 pr-4 reco-xxl:pr-0'

export const CustomRecommendationLinkWrapper =
  'font-GoodPro font-medium hover:underline underline-offset-2 text-14'

export const CustomRecommendationCarouselWrapper = 'min-h-[540px] max-h-max'

export const CustomRecommendationLoadingWrapper =
  'flex min-h-[540px] max-h-[556px]'

export const CustomRecommendationLoadingTile =
  'flex flex-col items-center justify-center shrink-0 grow-0 bg-machine2-25 animate-pulse w-[324px] min-h-[540px] max-h-[556px] mr-[14px] overflow-hidden'
