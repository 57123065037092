import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  NavItemComponent as BaseNavItemComponent,
  NavItemLinkTypes,
  NavItemComponentTypes
} from 'msp-components'
import { useMultiSite, useCookie } from 'msp-integrations'

const NavItemLink = ({
  link,
  linkType,
  colorScheme,
  cssClass,
  closeFlyout,
  children,
  eventName
}: NavItemLinkTypes) => {
  const { eventTracking } = useCookie()
  const { pathname } = useLocation()

  if (link === null || link === '#' || link === '') {
    link = '#'
  }

  const { buildUrl } = useMultiSite()

  const isExternal = link.indexOf('://') !== -1
  if (!isExternal && link !== '/') {
    link = buildUrl(link)
  }

  return (
    <>
      {isExternal ? (
        <a
          href={link}
          target={linkType}
          className={`navitem-${colorScheme} ${cssClass || ''}`}
        >
          {children}
        </a>
      ) : (
        <Link
          className={`navitem-${colorScheme} ${cssClass || ''}`}
          to={link}
          target={linkType}
          onClick={(e) => {
            if (eventName) {
              eventTracking(eventName, { event_action: children })
            }
            if (link === pathname) {
              e.preventDefault()
              closeFlyout && closeFlyout()
            }
          }}
        >
          {children}
        </Link>
      )}
    </>
  )
}

const NavItemComponent = ({
  data,
  regions,
  custom,
  closeFlyout,
  ...rest
}: NavItemComponentTypes) => {
  if (custom?.content?.link) {
    data.link = custom?.content?.link
  }
  if (closeFlyout) {
    data.closeFlyout = closeFlyout
  }

  return (
    <BaseNavItemComponent
      data={data}
      regions={regions}
      NavItemLink={NavItemLink}
      {...rest}
    />
  )
}

export default NavItemComponent
