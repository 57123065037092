export const baseOverlay = {
  mainWrapper: '',
  contentWrapper: '',
  contentContainer:
    'z-10 image-text-box-gradient justify-between w-full lg:h-[60%] lg:w-[43%] p-8 xl:h-[55%] xl:w-[40%] xl:p-16 lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:rounded',
  headline: 'my-8 lg:my-0',
  subHeadline: 'lg:mt-5',
  richText: '',
  imageWrapper: 'h-1',
  image: 'absolute top-0 left-0'
}

export const heroOverlay = {
  mainWrapper: '',
  contentWrapper: '',
  contentContainer: 'absolute top-1/2 -translate-y-1/2 px-8 lg:px-0',
  headline: 'mt-8 md:mt-0 mb-6 lg:my-0',
  subHeadline: 'lg:mt-5',
  richText: '',
  imageWrapper: 'absolute top-0 left-0 h-full w-full',
  image: 'absolute top-0 left-0'
}
export const serviceOverlay = {
  mainWrapper: 'grid grid-cols-1 lg:grid-cols-2',
  contentWrapper:
    'w-full pt-10 pb-14 px-8 lg:p-0 flex justify-center items-center bg-linenBackground',
  contentContainer: 'w-full lg:w-[70%] xl:w-[55%] my-4',
  headline: 'mb-8',
  subHeadline: 'mb-10',
  richText: '',
  imageWrapper: 'h-1',
  image: 'absolute top-0 left-0'
}

export const aboutOverlay = {
  mainWrapper: 'flex flex-wrap flex-col-reverse lg:flex-row-reverse',
  contentWrapper:
    'w-full p-8 lg:p-16 lg:mb-20 lg:pb-40 xl:pb-32 xl:pl-44 2xl:pr-56 m-auto xl:mt-5 lg:w-[60%] flex justify-around items-center',
  contentContainer: 'lg:max-w-xl',
  headline: 'lg:leading-normal mt-5 mb-6',
  subHeadline: '',
  richText: 'mt-8 md:mb-8 xl:mt-10 xl:mb-12',
  imageWrapper: 'w-full lg:w-[40%] object-cover h-auto',
  image: 'relative',
  bottomBanner: {
    container:
      'relative w-full flex flex-col items-center py-20 px-8 lg:absolute lg:left-0 lg:right-0 lg:top-full lg:-translate-y-1/2 lg:z-10 m-auto lg:grid lg:grid-cols-[1fr_auto] lg:py-0 lg:px-[5%] lg:w-[88%] lg:h-44 xl:h-80 bg-linenBackground',
    logo: 'w-[45%] lg:w-48 xl:w-[272px]',
    content:
      'flex w-fit flex-col justify-center text-center lg:ml-32 lg:text-left',
    richText: 'text-20 lg:text-16 xl:text-20 tracking-1.2 my-10 lg:my-0',
    socials: 'mt-3 flex justify-center lg:justify-start xl:mt-6'
  }
}

export const scrollableRichText =
  'hide-scrollbar overflow-scroll lg:h-[40%] xl:h-[55%] xl:max-h-[55%]'

export const ctaButton = 'mt-12 w-fit w-moz-fit border border-white lg:mt-0'
