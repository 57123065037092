const BUTTON_TYPES = {
  XS: {
    icon: 'Basket',
    type: 'tertiary',
    label: null,
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: ''
  },
  MD: {
    icon: '',
    type: 'primary',
    label: {
      id: 'msp.cart.add.to.cart',
      defaultMessage: 'Add to cart'
    },
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: 'w-full'
  },
  LG: {
    icon: '',
    type: 'primary',
    label: {
      id: 'msp.cart.add.to.cart',
      defaultMessage: 'Add to cart'
    },
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: 'w-full'
  },
  miniCart: {
    icon: 'Close',
    type: 'tertiary',
    label: null,
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: ''
  },
  TILE: {
    icon: '',
    type: 'primary',
    label: {
      id: 'msp.cart.add.to.cart',
      defaultMessage: 'Add to cart'
    },
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: 'w-full'
  },
  Stage: {
    icon: '',
    type: 'primary',
    label: {
      id: 'msp.cart.add.to.cart',
      defaultMessage: 'Add to cart'
    },
    ariaLabel: {
      id: 'msp.cart.add.to.cart.ariaLabel',
      defaultMessage: 'Add to cart'
    },
    className: 'w-full transition-colors duration-200'
  }
}

export { BUTTON_TYPES }
