import React from 'react'
import { IconsProps } from 'msp-integrations'

const NewsletterIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path
            d='M6.27,2.79v9H2.24v8.73c0.01,1.32,1.07,2.28,2.52,2.28c0.06,0,0.12,0,0.18,0h14.71c1.51,0,2.61-0.99,2.62-2.36V2.79H6.27z
			 M6.27,20.51c0,0.71-0.56,1.22-1.37,1.28H4.75v0c-0.89,0-1.51-0.53-1.52-1.28v-7.72h3.03V20.51z M21.26,20.43
			c0,0.8-0.67,1.36-1.62,1.36H6.87c0.25-0.36,0.39-0.79,0.4-1.28V3.79h13.99V20.43z'
          />
          <rect x='9.25' y='13.8' width='9.99' height='1' />
          <rect x='9.25' y='17.79' width='9.99' height='1' />
          <path d='M19.26,5.81l-10,0v5h10V5.81z M18.26,9.81h-8v-3l8,0V9.81z' />
        </g>
      </g>
    </svg>
  )
}

export default NewsletterIcon
