export const TileWrapper =
  'flex flex-col shrink-0 grow-0 border border-machine2-75 min-h-[540px] max-h-[556px] mr-3.5 overflow-hidden'

export const HeadWrapper = 'w-full relative'

export const ImageWrapper = 'flex w-full h-[285px]'

export const Image = 'w-full object-cover'

export const ImageBadgesWrapper =
  'flex absolute top-0 left-0 h-full w-full px-3 py-4'

export const InformationWrapper = 'flex flex-col justify-between grow bg-white'

export const ContentWrapper = 'flex flex-col px-3 py-6'

export const ActionsWrapper = 'flex flex-col w-full'

export const ProductName =
  'font-GoodPro font-medium text-18 leading-24 tracking-0.2 text-black max-h-6 overflow-hidden whitespace-nowrap text-ellipsis'

export const ProductDescription =
  'font-GoodPro text-14 leading-16 tracking-0.2 text-grayRegular max-h-6 overflow-hidden whitespace-nowrap text-ellipsis pt-2'
