import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ShowOnDesktop, ShowOnMobile } from 'msp-components'
import { transformRegionToMap, Region, useMultiSite } from 'msp-integrations'
import { IconButton } from '@/components/molecules/IconButton'
import { HeaderProps } from '@/components/organisms/Header/Header.types'

const defaults = {
  mobileHeaderHeight: 'h-[66px]',
  desktopHeaderHeight: 'md:h-[52px]',
  iconsHeight: '20px',
  iconsSpacing: 'space-x-[18px] md:space-x-6',
  backgroundColor: {
    transparent: 'bg-transparent',
    solid: 'bg-white'
  },
  contentColor: {
    light: 'text-white fill-white',
    dark: 'text-machine4-100 fill-machine4-100'
  }
}

const PuroHeaderCheckout = ({
  regions,
  isTransparent,
  lightContent,
  disableNavigation,
  ...props
}: HeaderProps) => {
  const [yPosition, setYPosition] = useState(0)
  const [showHeader, setShowHeader] = useState(true)

  const { buildUrl } = useMultiSite()
  const history = useHistory()
  const areas = transformRegionToMap(regions)

  const onLogoClick = () => {
    if (disableNavigation) return
    const path = buildUrl('/')
    history.push(path)
  }

  useEffect(() => {
    const handleScroll = () => {
      //forcing position to minimum 0 to avoid Safari bounce effect
      const position = Math.max(window.scrollY, 0)
      if (yPosition >= position) {
        setShowHeader(true)
      } else if (yPosition < position) {
        setShowHeader(false)
      }
      setYPosition(position)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [yPosition])

  const headerColors = `${
    isTransparent
      ? defaults.backgroundColor.transparent
      : defaults.backgroundColor.solid
  } ${
    isTransparent && lightContent
      ? defaults.contentColor.light
      : defaults.contentColor.dark
  }`

  return (
    <>
      <div
        className={`fixed top-0 w-screen px-6 md:px-10 ${
          defaults.mobileHeaderHeight
        } ${defaults.desktopHeaderHeight} ${headerColors} ${
          showHeader ? 'translate-y-0' : '-translate-y-full'
        } color opacity z-[100] transform transition duration-500`}
        {...props}
      >
        {!disableNavigation && <Region region={areas.get('modal')} />}
        <div className={'flex h-full items-center justify-center'}>
          <div className='w-auto flex-none cursor-pointer justify-self-center'>
            <ShowOnDesktop>
              <IconButton
                iconName='DarqLogo'
                height={defaults.iconsHeight}
                onClick={onLogoClick}
              />
            </ShowOnDesktop>
            <ShowOnMobile>
              <IconButton
                iconName='DarqLogoMain'
                height={defaults.iconsHeight}
                onClick={onLogoClick}
              />
            </ShowOnMobile>
          </div>
        </div>
      </div>
    </>
  )
}

export default PuroHeaderCheckout
