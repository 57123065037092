import React from 'react'
import FullWidthIntro from '@/components/molecules/FullWidthIntro/FullWidthIntro'

const FullWidthIntroWrapper = ({
  data,
  custom
}: {
  data: any
  regions: any
  custom: any
}) => {
  const imageSrc = custom?.content?.image?.path
  const buttonLink = custom?.content?.link

  const imageRatios = {
    // 16:9
    base_overlay: 'pt-[56.25%]',
    // 4:3, md 3:1
    hero_intro: 'pt-[75%] md:pt-[33.33%]',
    // 4:3
    service_intro: 'pt-[75%]'
  }

  switch (data?.overlay) {
    case 'base_overlay':
      return (
        <FullWidthIntro
          bgImage={imageSrc}
          imageAlt={data?.imageAlt}
          preline={data?.preline}
          headline={data?.headline}
          contentRichText={data?.contentRichText}
          contentPosition={data?.contentPosition}
          buttonLink={buttonLink}
          buttonText={data?.linkText}
          overlay='base'
          imageWrapperPaddingRatio={imageRatios[data.overlay]}
        />
      )
    case 'hero_intro':
      return (
        <FullWidthIntro
          bgImage={imageSrc}
          imageAlt={data?.imageAlt}
          contentPosition={data?.contentPosition}
          headline={data?.headline}
          subHeadline={data?.subHeadline}
          buttonLink={buttonLink}
          buttonText={data?.linkText}
          overlay='hero'
          imageWrapperPaddingRatio={imageRatios[data.overlay]}
        />
      )
    case 'service_intro':
      return (
        <FullWidthIntro
          bgImage={imageSrc}
          imageAlt={data?.imageAlt}
          headline={data?.headline}
          subHeadline={data?.subHeadline}
          contentRichText={data?.contentRichText}
          imageWrapperPaddingRatio={imageRatios[data.overlay]}
          overlay='service'
        />
      )
    case 'about_intro':
      return (
        <FullWidthIntro
          bgImage={imageSrc}
          imageAlt={data?.imageAlt}
          preline={data?.preline}
          headline={data?.headline}
          subHeadline={data?.subHeadline}
          contentRichText={data?.contentRichText}
          buttonLink={buttonLink}
          buttonText={data?.linkText}
          bannerRichText={data?.bannerRichText}
          overlay='about'
        />
      )
    default:
      return (
        <FullWidthIntro
          bgImage={imageSrc}
          imageAlt={data?.imageAlt}
          preline={data?.preline}
          headline={data?.headline}
          contentRichText={data?.contentRichText}
          contentPosition={data?.contentPosition}
          buttonLink={buttonLink}
          buttonText={data?.linkText}
        />
      )
  }
}

export default FullWidthIntroWrapper
