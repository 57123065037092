import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const DiamondRemovalIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path
            d='M1.5,9.16L12,22.17L22.5,9.16H1.5z M12,19.25l-3.69-9.09h7.36L12,19.25z M16.75,10.16h3.66l-7.32,9.07L16.75,10.16z
			 M7.23,10.16l3.68,9.06l-7.32-9.06H7.23z'
          />
          <polygon points='11.97,1.85 8.83,3.7 8.83,4.86 11.97,3.01 15.12,4.86 15.12,3.7 		' />
          <polygon points='15.12,6.73 11.97,4.89 8.83,6.73 8.83,7.89 11.97,6.05 15.12,7.89 		' />
        </g>
      </g>
    </svg>
  )
}

export default DiamondRemovalIcon
