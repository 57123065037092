import React from 'react'
import { SlideOver, Icons } from 'msp-components'
import { IconButton } from '@/components/molecules/IconButton'
import { PuroSearchBox } from '@/components/organisms/PuroSearchBox'
import * as T from './DesktopSearch.types'

const ICONS_HEIGHT = '20px'

/**
 * Header top bar that includes the search box
 */
const PuroDesktopSearch = ({
  showSearch,
  setShowSearch
}: T.DesktopSearchProps) => {
  const searchFormRef = React.useRef(null)
  // setTimeout is required to wait for the animation to be finished before removing the element
  const onCloseFlyout = () => setTimeout(() => setShowSearch(false), 100)

  return (
    <SlideOver
      position='top'
      backdropOpacity='bg-opacity-25'
      isOpen={showSearch}
      onOpen={() => searchFormRef?.current[0]?.focus()}
      onClose={onCloseFlyout}
    >
      <div className='font-GoodPro relative max-h-[100vh] min-h-[240px] w-full bg-white'>
        <div className='relative flex h-full items-start justify-between p-10'>
          <div className='relative top-6 transform-gpu'>
            <Icons iconName='DarqLogo' width='200px' height='100px' />
          </div>
          <PuroSearchBox
            className='-ml-20 mt-14 w-[600px] max-w-[50%]'
            formRef={searchFormRef}
            onResultClick={() => setShowSearch(false)}
            position='absolute'
            onClose={() => setShowSearch(false)}
            classNameResults='z-[1100]'
          />
          <div className='w-12'>
            <IconButton
              iconName='Close'
              onClick={onCloseFlyout}
              height={ICONS_HEIGHT}
            />
          </div>
        </div>
      </div>
    </SlideOver>
  )
}

export default PuroDesktopSearch
