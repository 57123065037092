export const discoveryWrapper = 'w-full lg:pb-40 m-auto lg:pt-28 md:px-8'
export const overarchingHeadline = 'pb-10 pl-8 md:pl-0 pt-14 pb-7'
export const gridWrapper = 'mx-auto h-auto w-full flex'
export const gridStyles =
  'w-full flex flex-rows lg:grid-rows-none gap-y-px lg:grid-cols-2 lg:gap-6 lg:w-3/5 lg:float-left lg:relative lg:pr-[5vw] lg:my-auto'
export const imageTextWrapper =
  'absolute flex w-full flex-row px-[8%] bottom-[8%]'
export const imageTextHeadlineWrapper = 'flex w-fit flex-col'
export const imageTextHeadline =
  'font-GoodPro font-medium text-white md:text-18 lg:text-20'
export const imageTextSubline =
  'font-GoodPro text-16 tracking-0.6 leading-24 pt-1 text-white md:text-14 lg:text-16'
export const imageIconsWrapper =
  'float-right ml-auto my-auto w-1/12 fill-white text-right'
export const tileInfoWrapper =
  'relative w-full px-8 py-14 lg:py-0 transition-all duration-500 lg:flex lg:flex-col lg:h-full lg:max-h-full'
export const tileInfoHeadline = 'mt-5 mb-8 lg:mb-5'
export const tileInfoRichText =
  'leading-28 text-justify lg:h-36 lg:overflow-auto lg:pr-4 lg:max-h-[12rem] lg:h-fit lg:mb-6'
export const illustrationWrapper = 'flex w-4/12 flex-row my-8'
export const iconsCommonStyle = 'pr-1 w-1/3'
export const puroButtonWrapper = 'w-full'
