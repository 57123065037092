import React from 'react'
import { IconsProps } from 'msp-integrations'

const MailIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path d='M2,5.02v14.02h20V5.02H2z M20.4,6.02l-8.38,6.43L3.63,6.02H20.4z M3,18.04V6.8l9.02,6.91L21,6.82v11.22H3z' />
      </g>
    </svg>
  )
}

export default MailIcon
