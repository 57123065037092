import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import { useServerContext } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'

// Localization
import { IntlProvider } from 'react-intl'

// Others
import PuroChat from '@/components/atoms/PuroChat/PuroChat'
import Seo from '@/components/seo'
import {
  DEFAULT_SITE_TITLE,
  CAT_MENU_DEFAULT_NAV_DEPTH,
  CAT_MENU_DEFAULT_ROOT_CATEGORY,
  DEFAULT_LOCALE,
  HOME_HREF
} from '@/constants'
import { resolveSiteFromUrl } from '@/utils/site-utils'
import { fetchTranslations, getTargetLocale } from '@/utils/locale'
import { getParamsFromPath } from '@/utils/utils'
import {
  useMultiSite,
  useShopper,
  flatten,
  LanguageProvider,
  CategoriesProvider,
  ChatProvider,
  useNavigation,
  CurrencyProvider,
  useCustomer,
  useWishlist,
  isServer
} from 'msp-integrations'
import { CookieModal } from 'msp-components'
import MaintenancePageWrapper from '@/pages/maintenance'
import packageJson from '../../../package.json'

const App = (props: any) => {
  const {
    children,
    targetLocale = DEFAULT_LOCALE,
    messages,
    categories: allCategories = {},
    paymentError,
    orderNo,
    token
  } = props

  const {
    maintenanceMode,
    externalIntegrations: { sentryDSN, sentryEnvironment }
  } = getConfig()
  const appOrigin = getAppOrigin()
  const customer = useCustomer()
  const wishlist = useWishlist()
  const inMaintenanceMode = maintenanceMode === 'true'
  const navigate = useNavigation()
  const { site, locale, buildUrl } = useMultiSite()
  const { res } = useServerContext()

  useEffect(() => {
    if (sentryDSN && sentryDSN !== 'undefined') {
      const history = createBrowserHistory()
      Sentry.init({
        dsn: atob(sentryDSN),
        release: `${packageJson.name}@${packageJson.version}`,
        dist: packageJson.version,
        environment: sentryEnvironment,
        integrations: [
          Sentry.reactRouterV5BrowserTracingIntegration({ history }),
          Sentry.replayIntegration({
            workerUrl: '/static/js/sentry-replay-worker.min.js'
          })
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
          /^Can't find variable: (fbq|pintrk)$/,
          /^(fbq|pintrk) is not defined$/
        ]
      })
    }
  }, [sentryDSN])

  // if locale is missing in the path, user will be redirected, to default locale with the path
  const originalUrl = isServer
    ? res.locals.originalUrl
    : window.location.pathname
  const { localeRef } = getParamsFromPath(originalUrl)
  if (!localeRef && originalUrl !== HOME_HREF) {
    if (!isServer) {
      navigate(originalUrl)
      return <></>
    } else {
      res.redirect(301, buildUrl(originalUrl))
      return <></>
    }
  }

  const { l10n } = site
  // Get the current currency to be used through out the app
  const currency = locale.preferredCurrency || l10n.defaultCurrency

  // Set up customer and basket
  useShopper({ currency, paymentError, orderNo, token })

  useEffect(() => {
    if (!customer.isInitialized) {
      return
    }
    if (customer.isRegistered) {
      wishlist.init()
    }
    if (customer.isGuest) {
      wishlist.reset()
    }
  }, [customer.authType])

  return (
    <div className='sf-app flex min-w-[320px] flex-1 flex-col'>
      <IntlProvider
        onError={(err) => {
          if (err.code === 'MISSING_TRANSLATION') {
            // NOTE: Remove the console error for missing translations during development,
            // as we knew translations would be added later.
            console.warn('Missing translation', err.message)
            return
          }
          // Commenting the following line leads to better errors showed when missing translations and to
          // fix the HMR behaviour that was giving constants errors when running
          // throw err
        }}
        locale={targetLocale}
        messages={messages}
        // For react-intl, the _default locale_ refers to the locale that the inline `defaultMessage`s are written for.
        // NOTE: if you update this value, please also update the following npm scripts in `pwa/package.json`:
        // - "extract-default-translations"
        // - "compile-translations:pseudo"
        defaultLocale={DEFAULT_LOCALE}
        key={targetLocale}
      >
        <CategoriesProvider treeRoot={allCategories} locale={targetLocale}>
          <CurrencyProvider currency={currency}>
            <LanguageProvider locale={locale.id}>
              <ChatProvider isInitialized={false}>
                <Seo htmlAttributes={{ lang: locale.id }}>
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/GoodPro.otf')}
                    as='font'
                    type='font/otf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/GoodPro-Medium.otf')}
                    as='font'
                    type='font/otf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/GoodPro-WideMedium.otf')}
                    as='font'
                    type='font/otf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/GoodPro-Wide.otf')}
                    as='font'
                    type='font/otf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/Barlow-Regular.ttf')}
                    as='font'
                    type='font/ttf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='preload'
                    href={getAssetUrl('static/css/Barlow-Medium.ttf')}
                    as='font'
                    type='font/ttf'
                    crossOrigin='anonymous'
                  />
                  <link
                    rel='stylesheet'
                    href={getAssetUrl('static/css/styles.css')}
                    as='style'
                  />
                  <meta
                    name='apple-mobile-web-app-title'
                    content={DEFAULT_SITE_TITLE}
                  />
                  <meta name='theme-color' content='white' />
                  <link
                    rel='apple-touch-icon'
                    href={getAssetUrl('static/img/global/apple-touch-icon.png')}
                  />
                  <link
                    rel='manifest'
                    href={getAssetUrl('static/manifest.json')}
                  />
                  {/* A wider fallback for user locales that the app does not support */}
                  <link
                    rel='alternate'
                    hrefLang='x-default'
                    href={`${appOrigin}/`}
                  />
                </Seo>
                {!inMaintenanceMode && <CookieModal />}
                <div id='app' className='flex flex-1 flex-col'>
                  <div id='app-main' className='flex flex-1 flex-col'>
                    {inMaintenanceMode ? <MaintenancePageWrapper /> : children}
                  </div>
                </div>
                <PuroChat WebOriginCountry={'DE'} />
              </ChatProvider>
            </LanguageProvider>
          </CurrencyProvider>
        </CategoriesProvider>
      </IntlProvider>
    </div>
  )
}

App.shouldGetProps = () => {
  // In this case, we only want to fetch data for the app once, on the server.
  return typeof window === 'undefined'
}

App.getProps = async ({ api, res, req }: any) => {
  const { paymentError, orderNo, token } = req.query
  const site = resolveSiteFromUrl(res.locals.originalUrl)
  const l10nConfig = site.l10n

  // Remove old header
  res.removeHeader('Expect-Ct')

  const targetLocale = getTargetLocale({
    getUserPreferredLocales: () => {
      // CONFIG: This function should return an array of preferred locales. They can be
      // derived from various sources. Below are some examples of those:
      //
      // - client side: window.navigator.languages
      // - the page URL they're on (example.com/en-GB/home)
      // - cookie (if their previous preference is saved there)
      //
      // If this function returns an empty array (e.g. there isn't locale in the page url),
      // then the app would use the default locale as the fallback.

      // NOTE: Your implementation may differ, this is just what we did.
      //
      // Since the CommerceAPI client already has the current `locale` set,
      // we can use it's value to load the correct messages for the application.
      // Take a look at the `app/components/_app-config` component on how the
      // preferred locale was derived.
      const { locale } = api.getConfig()

      return [locale]
    },
    l10nConfig
  })
  const messages = await fetchTranslations(targetLocale)

  // Login as `guest` to get session.
  await api.auth.login()

  // Get the root category, this will be used for things like the navigation.
  const rootCategory = await api.shopperProducts.getCategory({
    parameters: {
      id: CAT_MENU_DEFAULT_ROOT_CATEGORY,
      levels: CAT_MENU_DEFAULT_NAV_DEPTH
    }
  })

  if (rootCategory.isError) {
    const message =
      rootCategory.title === 'Unsupported Locale'
        ? `
It looks like the locale “${rootCategory.locale}” isn’t set up, yet. The locale settings in your package.json must match what is enabled in your Business Manager instance.
Learn more with our localization guide. https://sfdc.co/localization-guide
`
        : rootCategory.detail
    throw new Error(message)
  }

  // Flatten the root so we can easily access all the categories throughout
  // the application.
  const categories = { root: flatten(rootCategory, 'categories').root }

  return {
    targetLocale,
    messages,
    categories,
    paymentError,
    orderNo,
    token
  }
}

App.propTypes = {
  children: PropTypes.node,
  targetLocale: PropTypes.string,
  messages: PropTypes.object,
  rootCategory: PropTypes.object,
  categories: PropTypes.object,
  paymentError: PropTypes.string,
  orderNo: PropTypes.string,
  token: PropTypes.string
}

export default App
