import React from 'react'
import { IconsProps } from 'msp-integrations'

const BarcodeIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path d='M2,4.08v15.98l20,0V4.08H2z M21,19.06l-18,0V5.08h18V19.06z' />
          <rect x='4.99' y='8.05' width='1' height='8.01' />
          <rect x='7.58' y='8.05' width='1' height='8.01' />
          <rect x='10.18' y='8.05' width='1' height='8.01' />
          <rect x='12.78' y='8.05' width='1' height='8.01' />
          <rect x='15.37' y='8.05' width='1' height='8.01' />
          <rect x='17.97' y='8.05' width='1' height='8.01' />
        </g>
      </g>
    </svg>
  )
}

export default BarcodeIcon
