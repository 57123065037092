import React from 'react'
import loadable from '@loadable/component'

// Components
import { configureRoutes } from './utils/routes-utils'
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import { Spinner } from 'msp-components'

const fallback = <Spinner fullScreen />

// Pages
const Home = loadable(() => import('./pages/page'), { fallback })
const Page = loadable(() => import('./pages/page'), { fallback })
const PageNotFound = loadable(() => import('./pages/page-not-found'), {
  fallback
})
const PLP = loadable(() => import('./pages/product-list'), { fallback })
const Login = loadable(() => import('./pages/login'), { fallback })
const Registration = loadable(() => import('./pages/registration'), {
  fallback
})
const ResetPassword = loadable(() => import('./pages/reset-password'), {
  fallback
})
const Account = loadable(() => import('./pages/account'), { fallback })
const Search = loadable(() => import('./pages/search'), { fallback })
const Cart = loadable(() => import('./pages/cart'), { fallback })
const POSStart = loadable(() => import('./pages/pos-start'), { fallback })
const PDP = loadable(() => import('./pages/product-detail'), { fallback })
const Checkout = loadable(() => import('./pages/checkout'), { fallback })
const Confirmation = loadable(() => import('./pages/checkout/confirmation'), {
  fallback
})
const Maintenance = loadable(() => import('./pages/maintenance'), {
  fallback
})

const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/registration',
    component: Registration,
    exact: true
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    exact: true
  },
  {
    path: '/account',
    component: Account
  },
  {
    path: '/checkout',
    component: Checkout,
    exact: true
  },
  {
    path: '/checkout/confirmation',
    component: Confirmation,
    exact: true
  },
  {
    path: '/cart',
    component: Cart,
    exact: true
  },
  {
    path: '/:productPath([p{ü}p{ö}p{ä}a-zA-Z0-9-]+/[p{ü}p{ö}p{ä}a-zA-Z0-9-]+/[p{ü}p{ö}p{ä}a-zA-Z0-9€-]+-\\d{18})',
    component: PDP
  },
  {
    path: '/:productPath([p{ü}p{ö}p{ä}a-zA-Z0-9-]+/[p{ü}p{ö}p{ä}a-zA-Z0-9€-]+-\\d{18})',
    component: PDP
  },
  {
    path: '/search',
    component: Search
  },
  {
    path: '/shop/:categoryId',
    component: PLP
  },
  {
    path: '/shop',
    component: PLP
  },
  {
    path: '/pos-start',
    component: POSStart
  },
  {
    path: '/page-not-found',
    component: PageNotFound
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '/*',
    component: Page
  }
]

export default () => {
  const config = getConfig()
  return configureRoutes(routes, config, {
    ignoredRoutes: ['/callback', '*']
  })
}
