import React from 'react'
import { IconsProps } from '../../../types/IconsType'

const DiamondOpenIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M21.52,3.96l-5.21,5.03L13.3,3.96H10.7L7.69,8.98L2.5,3.96h-1v5.2L12,22.17L22.5,9.16v-5.2H21.52z M11.27,4.96h1.46
			l2.52,4.2h-6.5L11.27,4.96z M2.5,5.35l3.94,3.81H2.5V5.35z M3.59,10.16h3.64l3.68,9.06L3.59,10.16z M12,19.25l-3.69-9.09h7.36
			L12,19.25z M13.09,19.23l3.66-9.07h3.66L13.09,19.23z M17.57,9.16l3.93-3.8v3.8H17.57z'
        />
      </g>
    </svg>
  )
}

export default DiamondOpenIcon
