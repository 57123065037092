import React from 'react'
import { IconsProps } from 'msp-integrations'

const WishlistIcon = (props: IconsProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1069 11.6071L11.9879 19.7001L3.89792 11.6091C2.22492 9.92108 2.34192 7.45708 4.18092 5.61808C5.15692 4.64208 6.30692 4.14908 7.42492 4.14908C8.41892 4.14908 9.38492 4.53908 10.1789 5.32508L12.0019 7.14608L13.8209 5.32708C15.5099 3.65308 17.9739 3.77008 19.8129 5.60908C21.6559 7.45308 21.7759 9.92008 20.1069 11.6071ZM20.5199 4.90208C18.3029 2.68608 15.1879 2.56408 13.1159 4.61808L12.0019 5.73208L10.8839 4.61608C8.81292 2.56608 5.69692 2.68708 3.47392 4.91108C1.25592 7.12908 1.13592 10.2421 3.18892 12.3151L11.9859 21.1131L20.8149 12.3131C22.8659 10.2411 22.7419 7.12508 20.5199 4.90208Z'
      />
    </svg>
  )
}

export default WishlistIcon
