import React from 'react'
import { IconsProps } from 'msp-integrations'

const BrewingProcessIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M22,2.01h-6.04v4.01H8.52c-3.76,0-6.5,2.59-6.52,6.19v9.77h1l0-9.8c0.01-2.99,2.34-5.17,5.52-5.17h7.44v4.02h2.03v0.97h2
		v-0.97H22V2.01z M21,10.04h-4.04V3.01H21V10.04z'
        />
        <polygon points='12.52,13.98 14.82,17.99 12.52,21.99 13.67,21.99 15.98,17.99 13.68,13.98 	' />
        <polygon points='7.57,21.99 8.72,21.99 11.01,17.99 8.72,13.98 7.57,13.98 9.86,17.99 	' />
      </g>
    </svg>
  )
}

export default BrewingProcessIcon
