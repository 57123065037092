import { scroller } from 'react-scroll'

export const handleLinkClick = (linkType, linkHref) => {
  switch (linkType) {
    case 'Chat': {
      //To trigger the external chat plugin from service cloud
      const button: HTMLSpanElement = document.querySelector(
        '#helpButtonSpan > span.message'
      )
      if (button) button.click()
      break
    }
    case 'Contact Form':
      scroller.scrollTo(linkHref, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
      break
  }
}
