import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
// @ts-ignore
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import {
  useCountryData,
  useChat,
  useCookie,
  useEffectSkipFirst
} from 'msp-integrations'

export type ChatTypes = {
  WebOriginCountry: string
  Brand?: string
  showAfterSeconds?: number
}

const Chat = ({
  WebOriginCountry,
  Brand = 'Darq',
  showAfterSeconds = 5
}: ChatTypes) => {
  const { isFunctionalAllowed } = useCookie()
  const { isInitialized, setIsInitialized } = useChat()
  const { puroChatDetails } = getConfig()
  const i18n = useCountryData()
  const { formatMessage } = useIntl()

  // @ts-ignore
  useEffect(() => {
    if (!isInitialized && isFunctionalAllowed) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const timer = setTimeout(createChat, showAfterSeconds * 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isFunctionalAllowed])

  useEffectSkipFirst(isFunctionalAllowed, () => {
    if (!isFunctionalAllowed) {
      location.reload()
    }
  })

  const initChat = (gslbBaseURL?: string | null): void => {
    // @ts-ignore
    const { embedded_svc } = window
    if (embedded_svc) {
      embedded_svc.settings.displayHelpButton = true
      embedded_svc.settings.language = i18n.locale?.split('_')[0]
      embedded_svc.settings.enabledFeatures = ['LiveAgent']
      embedded_svc.settings.entryFeature = 'LiveAgent'
      embedded_svc.settings.defaultMinimizedText = formatMessage({
        id: 'msp.chat.defaultMinimizedText',
        defaultMessage: 'How can I help you?'
      })
      embedded_svc.settings.disabledMinimizedText = formatMessage({
        id: 'msp.chat.disabledMinimizedText',
        defaultMessage: 'Chat offline'
      })
      embedded_svc.settings.loadingText = formatMessage({
        id: 'msp.chat.loadingText',
        defaultMessage: 'Loading...'
      })
      embedded_svc.settings.extraPrechatFormDetails = [
        { label: 'First Name', transcriptFields: [], displayToAgent: true },
        { label: 'Last Name', transcriptFields: [], displayToAgent: true },
        { label: 'Email', transcriptFields: [], displayToAgent: true },
        { label: 'issue', transcriptFields: [], displayToAgent: true },
        {
          label: 'Web Origin URL',
          value: Brand + WebOriginCountry,
          displayToAgent: true
        }
      ]
      embedded_svc.settings.extraPrechatInfo = [
        {
          entityName: 'Contact',
          showOnCreate: true,
          linkToEntityName: 'Case',
          linkToEntityField: 'ContactId',
          saveToTranscript: 'Contact',
          entityFieldMaps: [
            {
              isExactMatch: true,
              fieldName: 'FirstName',
              doCreate: true,
              doFind: true,
              label: 'First Name'
            },
            {
              isExactMatch: true,
              fieldName: 'LastName',
              doCreate: true,
              doFind: true,
              label: 'Last Name'
            },
            {
              isExactMatch: true,
              fieldName: 'Email',
              doCreate: true,
              doFind: true,
              label: 'Email'
            }
          ]
        },
        {
          entityName: 'Case',
          entityFieldMaps: [
            {
              isExactMatch: false,
              fieldName: 'Subject',
              doCreate: true,
              doFind: false,
              label: 'issue'
            },
            {
              isExactMatch: false,
              fieldName: 'Web_Origin_URL__c',
              doCreate: true,
              doFind: false,
              label: 'Web Origin URL'
            }
          ]
        }
      ]

      embedded_svc.init(
        puroChatDetails.SVCUrl1,
        puroChatDetails.SVCUrl2,
        gslbBaseURL,
        puroChatDetails.SVCPublicId,
        `${Brand}` + '_Web_Chat',
        {
          baseLiveAgentContentURL: puroChatDetails.baseLiveAgentContentURL,
          deploymentId: puroChatDetails.deploymentId,
          buttonId: puroChatDetails.buttonId,
          baseLiveAgentURL: puroChatDetails.baseLiveAgentURL,
          eswLiveAgentDevName: puroChatDetails.eswLiveAgentDevName,
          isOfflineSupportEnabled: false
        }
      )
    }
  }

  const createChat = () => {
    // @ts-ignore
    if (typeof window !== 'undefined' && !window.embedded_svc) {
      const s = window.document.createElement('script')
      s.setAttribute('type', 'text/javascript')
      s.setAttribute('src', puroChatDetails)
      s.onload = () => {
        setIsInitialized(true)
        initChat(null)
      }
      window.document.body.appendChild(s)
    } else {
      initChat('https://service.force.com')
    }
  }

  return (
    <script
      type='text/javascript'
      src='https://service.force.com/embeddedservice/5.0/esw.min.js'
    />
  )
}

export default Chat
