import { defineMessage } from 'react-intl'
import { getConfig as getConfigMSP } from 'msp-components'
import PuroSVGMapping from './PuroSVGMapping'

const tenantId = 'puro'

const getConfig = (ssrConfig?: any) =>
  getConfigMSP(
    {
      title: 'Darq',
      titleNewsletter: 'Darq',
      tenantId,
      cookiePrefix: 'Darq',
      gtmDomain: 'www.googletagmanager.com',
      theme: {
        iconMapping: PuroSVGMapping,
        font: {
          regular: 'font-GoodPro',
          medium: 'font-GoodPro font-medium',
          bold: 'font-GoodPro font-semibold',
          wide: 'font-GoodPro-Wide'
        },
        fontVariant: {
          regular: 'font-GoodPro',
          medium: 'font-GoodPro font-medium',
          bold: 'font-GoodPro font-semibold',
          wide: 'font-GoodPro-Wide'
        },
        mspComponents: {
          input: {
            default: {
              div: 'focus-within:text-machine4-100 focus-within:stroke-machine4-100',
              label: 'group-hover:text-machine4-100',
              input:
                'text-machine4-100 group-hover:border-machine4-100 placeholder-machine4-50 placeholder:tracking-0.2 focus:border-machine4-100 focus:ring-coffee1-100',
              passwordIcon: 'group-hover:stroke-machine4-100'
            },
            underline: {
              input:
                'border-machine4-100 text-machine4-100 placeholder-machine4-100 placeholder:font-GoodPro placeholder:tracking-0.2',
              input_Dark:
                'border-machine4-100 text-white placeholder-machine4-100 placeholder:font-GoodPro placeholder:tracking-0.2'
            },
            textColor: 'text-machine4-100'
          },
          button: {
            layoutColors: {
              default: 'bg-black fill-white text-white',
              outline: 'bg-white fill-black text-black border border-black',
              danger:
                'bg-white fill-errorRed text-errorRed border border-errorRed hover:border-black',
              square: 'bg-coffee1-100 fill-black text-black'
            },
            backgroundTransitionColors: {
              default: 'before:bg-coffee1-100',
              outline: 'before:bg-black',
              danger: 'before:bg-black',
              square: 'before:bg-black'
            },
            contentColors: {
              default:
                'md:group-hover:text-black md:group-hover:fill-black transition-colors',
              outline:
                'md:group-hover:text-white md:group-hover:fill-white transition-colors',
              square:
                'md:group-hover:text-white md:group-hover:fill-white transition-colors'
            }
          },
          checkbox: {
            getStyles: (darkmode) => ({
              labelStyle: 'text-14 leading-16 font-GoodPro tracking-0.2',
              enabledCheckStyle: 'border-machine4-100 fill-white',
              disabledCheckStyle:
                'border-machine2-50 bg-machine2-25 fill-white',
              valueTrueStyle: 'bg-machine4-100'
            })
          },
          radiobutton: {
            getStyles: (darkmode) => ({
              label:
                'text-14 leading-16 my-2 inline-flex w-fit cursor-pointer items-center tracking-[0.2px]',
              labelWrapper: 'm-0 h-fit',
              initial: 'font-GoodPro bg-white border-machine4-100',
              checked: 'checked:bg-white checked:after:border-machine4-100',
              disabled: 'disabled:bg-machine2-25 disabled:border-machine2-50',
              labelValueVariant: 'font-GoodPro text-16',
              labelWrapperValueVariant:
                'h-9 w-fit px-4 py-2 border border-black rounded-md'
            })
          },
          spinner: {
            position: 'left-0 top-0 right-0 bottom-0',
            fullscreenContainer: {
              dark: 'bg-white',
              light: 'bg-white'
            },
            label: {
              dark: 'text-black',
              light: 'text-black'
            }
          },
          requestPasswordModal: {
            header: 'tracking-0.8',
            detail: 'tracking-0.6'
          },
          loginForm: { tracking: 'tracking-[0.2px]', fontSize: 'text-12' },
          accountDataSharing: 'xl:text-18',
          headlineSubline: 'tracking-0.8 md:tracking-1',
          footerCheckout: 'tracking-1',
          footer: {
            link: 'tracking-0.6',
            gridWrapperStyles: 'tracking-1',
            priceInfoWrapper: 'tracking-0.2',
            icon: 'DarqLogo',
            iconWidth: '250px'
          },
          pageActionPlaceHolder: {
            Wrapper:
              'flex flex-col w-full bg-lightGray justify-center items-center px-4 py-8',
            IconWrapper: 'text-grayRegular',
            Headline: 'font-GoodPro font-medium text-18 pt-4 pb-2 text-black',
            ContentText: 'font-GoodPro text-14 text-black',
            ActionsWrapper: 'pt-6'
          },
          wishlistButton:
            'bg-machine2-25 border-none cursor-default fill-black focus:ring-0 focus:outline-none focus:shadow-none',
          headlineFont: 'font-medium',
          newsletterForm: 'tracking-0.6',
          newsletterModal: {
            title: 'leading-14 tracking-4',
            success: 'tracking-0.6'
          },
          contactForm: {
            reasonFieldName: 'Contact_Reason_darq__c',
            reasons: [
              {
                id: 'Bestellung und Lieferung',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.orders_and_delivery',
                  defaultMessage: 'Orders and Delivery'
                })
              },
              {
                id: 'Datenschutz',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.data_privacy',
                  defaultMessage: 'Data Privacy'
                })
              },
              {
                id: 'Food &amp; Accessoires (inkl. Ersatzteile)',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.food_accesories',
                  defaultMessage: 'Food & Accessories (incl. spare parts)'
                })
              },
              {
                id: 'Handel (Trade)',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.trade',
                  defaultMessage: 'Retail'
                })
              },
              {
                id: 'Kaffee',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.coffee',
                  defaultMessage: 'Coffee'
                })
              },
              {
                id: 'Kundenkonto und Buchhaltung',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.customer_account_and_accounting',
                  defaultMessage: 'Customer Account and Accounting'
                })
              },
              {
                id: 'Marketing und Promotion',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.marketing_promotion',
                  defaultMessage: 'Marketing and Promotion'
                })
              },
              {
                id: 'Maschine',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.machine',
                  defaultMessage: 'Machine'
                })
              },
              {
                id: 'Maschine: Technischer Service',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.machine_tech_service',
                  defaultMessage: 'Machine: Technical Service'
                })
              },
              {
                id: 'Spam',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.spam',
                  defaultMessage: 'Spam'
                })
              },
              {
                id: 'Störfall Konnektivität',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.connectivity_failure',
                  defaultMessage: 'Connectivity failure'
                })
              },
              {
                id: 'Website',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.website',
                  defaultMessage: 'Website'
                })
              }
            ]
          },
          topNewsletterBanner: {
            getBgColor: (backgroundColor = 'black') => {
              const colors = {
                black: 'bg-black',
                lightBrown: 'bg-coffee1-100',
                darkBrown: 'bg-coffee3-100'
              }

              return colors[backgroundColor]
            },
            getIcon: (backgroundColor = 'black') => {
              const icons = {
                black: 'fill-black',
                lightBrown: 'fill-coffee1-100',
                darkBrown: 'fill-coffee3-100'
              }

              return icons[backgroundColor]
            },
            getColor: (backgroundColor = 'black') => {
              const colors = {
                black: 'placeholder-white border-white text-white fill-white',
                lightBrown:
                  'placeholder-black border-black text-black fill-black',
                darkBrown:
                  'placeholder-white border-white text-white fill-white'
              }
              return colors[backgroundColor]
            },
            getErrorColor: (backgroundColor = 'jasmin_lightGreen') => {
              const errorRed =
                'border-errorRed text-errorRed group-hover:text-errorRed focus:border-errorRed placeholder-errorRed'
              const colors = {
                jasmin_lightGreen: errorRed,
                jasmin_darkGreen: errorRed,
                jasmin_red: errorRed
              }
              return colors[backgroundColor]
            }
          },
          progressBar: {
            containerBgColor: 'text-black bg-secondaryCoffee1',
            barColor: 'bg-coffee3-100'
          },
          cartPromotionSlot: {
            getBgColor: (backgroundColor = 'black') => {
              const colors = {
                black: 'bg-black',
                lightBrown: 'bg-coffee1-100',
                darkBrown: 'bg-coffee3-100'
              }

              return colors[backgroundColor]
            }
          }
        }
      },
      info: {
        email: 'service@darq.com',
        brand: 'DARQ',
        url: 'https://darq.de',
        team: 'Darq team',
        supportEmail: 'support@darq.com'
      }
    },
    ssrConfig
  )
export default getConfig
