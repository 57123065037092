import React from 'react'
import { transformRegionToMap } from 'msp-components'
import { configureDISBaseLink } from 'msp-integrations'
import PuroProductDetails from '@/components/molecules/PuroProductDetails/PuroProductDetails'

const ProductDetailsWrapper = ({
  data,
  regions,
  product,
  custom
}: {
  data: any
  regions: any
  product: any
  custom: any
}) => {
  const content = transformRegionToMap(regions)?.get('content')
  const imageSrc = custom?.content?.image?.path
    ? configureDISBaseLink(custom?.content?.image?.path, {
        scaleWidth: 960
      })
    : null

  return product ? (
    <PuroProductDetails
      product={product}
      imagePosition={data.imagePosition}
      bgStyle={data.bgStyle}
      imageAlt={data.imageAlt}
      imageSrc={imageSrc}
      content={content}
    />
  ) : (
    <></>
  )
}

export default ProductDetailsWrapper
