import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import {
  Region,
  transformRegionToMap,
  useCountryData,
  useCustomer,
  useBasket,
  useNavigation,
  useMultiSite,
  useCookie
} from 'msp-integrations'
import {
  ShowOnDesktop,
  ShowOnMobile,
  LanguageModal,
  EventsEmitter,
  Events,
  CustomLink
} from 'msp-components'
import { IconButton } from '@/components/molecules/IconButton'
import { UserHeaderIcon } from '@/components/molecules/UserHeaderIcon'
import PuroMiniCart from '@/components/organisms/PuroMiniCart/PuroMiniCart'
import PuroDesktopSearch from '@/components/organisms/PuroDesktopSearch/PuroDesktopSearch'
import ChoiceOfBonusProductsModal from '@/components/organisms/ChoiceOfBonusProductsModal/ChoiceOfBonusProductsModal'
import FixedHeaderWrapper from './partials/FixedHeaderWrapper'
import { HeaderProps } from './Header.types'
import { MIN_AMOUNT_FREE_SHIPPING, MIN_BOXES_FREE_DELIVERY } from '@/constants'

const defaults = {
  mobileHeaderHeight: 'h-[66px]',
  desktopHeaderHeight: 'md:h-[52px]',
  iconsHeight: '20px',
  iconsSpacing: 'space-x-[18px] md:space-x-6',
  backgroundColor: {
    transparent: 'bg-transparent',
    solid: 'bg-white'
  },
  contentColor: {
    light: 'text-white fill-white',
    dark: 'text-machine4-100 fill-machine4-100'
  }
}

const Header = ({
  regions,
  isTransparent,
  lightContent,
  onMenuClick,
  onUserClick
}: HeaderProps) => {
  const { eventTracking } = useCookie()
  const i18n = useCountryData()
  const customer = useCustomer()
  const navigate = useNavigation()
  const [showMiniCart, setShowMiniCart] = useState(false)
  const basket = useBasket()
  const { buildUrl } = useMultiSite()
  const { path } = useRouteMatch()
  const inCartPage = path && path.includes('/cart')
  const [showLangModal, setShowLangModal] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const areas = transformRegionToMap(regions)
  const addToCartEmitter = useRef(EventsEmitter)
  const isMounted = useRef(false)
  const { c_minAmountFreeShipping, c_minBoxesFreeDelivery } = basket
  const minAmountFreeShipping =
    c_minAmountFreeShipping || MIN_AMOUNT_FREE_SHIPPING
  const minBoxesFreeDelivery = c_minBoxesFreeDelivery || MIN_BOXES_FREE_DELIVERY

  useEffect(() => {
    isMounted.current = true

    const subscription = addToCartEmitter.current?.subscribe(
      Events.ADD_TO_CART_SUCCESS,
      () => {
        if (!showMiniCart && isMounted.current) {
          setShowMiniCart(true)
        }
      }
    )

    return () => {
      isMounted.current = false
      subscription?.unsubscribe()
    }
  }, [showMiniCart])

  const headerColors = `${
    isTransparent
      ? defaults.backgroundColor.transparent
      : defaults.backgroundColor.solid
  } ${
    isTransparent && lightContent
      ? defaults.contentColor.light
      : defaults.contentColor.dark
  }`

  const handleOpenLanguageModal = () => {
    eventTracking('header_tracking', { event_action: 'language' })
    setShowLangModal(true)
  }

  const wrapperStyles = `${defaults.mobileHeaderHeight} ${defaults.desktopHeaderHeight} ${headerColors}`

  return (
    <>
      <FixedHeaderWrapper className={wrapperStyles}>
        <div
          id='main-header'
          className={'flex h-full items-center justify-center'}
        >
          <div className='flex-auto md:flex-1'>
            <div className={'flex justify-start pr-[18px] md:pr-6'}>
              <IconButton
                iconName='Menu'
                width={defaults.iconsHeight}
                height='20px'
                onClick={onMenuClick}
                event_name='header_tracking'
                event_action='burgermenu'
              />
            </div>
          </div>

          <CustomLink
            className='w-auto flex-none cursor-pointer justify-self-center'
            colorScheme='nothing'
            href={buildUrl('/')}
            title='Darq'
          >
            <ShowOnDesktop>
              <IconButton
                iconName='DarqLogo'
                height={defaults.iconsHeight}
                width='157px'
                event_name='header_tracking'
                event_action='logo'
              />
            </ShowOnDesktop>
            <ShowOnMobile>
              <IconButton
                iconName='DarqLogoMain'
                height={defaults.iconsHeight}
                width='106px'
                event_name='header_tracking'
                event_action='logo'
              />
            </ShowOnMobile>
          </CustomLink>

          <div className='flex-auto md:flex-1'>
            <div
              className={`flex items-center justify-end ${defaults.iconsSpacing}`}
            >
              {/*TODO: replace by dedicated components (basket, user, search, lang switch)*/}
              <ShowOnDesktop>
                <IconButton
                  iconName='Search'
                  onClick={() => setShowSearch(true)}
                  height={defaults.iconsHeight}
                  width={defaults.iconsHeight}
                  event_name='header_tracking'
                  event_action='search'
                />
              </ShowOnDesktop>
              <UserHeaderIcon
                onClick={onUserClick}
                iconSize={defaults.iconsHeight}
                isAuthenticated={customer && customer.isRegistered}
                customerName={
                  customer && customer.isRegistered
                    ? `${customer.firstName} ${customer.lastName}`
                    : ''
                }
              />
              <IconButton
                iconName={'Wishlist'}
                onClick={() => navigate('/account/wishlist')}
                height={'18px'}
                width={'18px'}
                event_name='header_tracking'
                event_action='wishlist'
              />
              <div>
                <IconButton
                  iconName={'Basket'}
                  onClick={() => setShowMiniCart(true)}
                  height={defaults.iconsHeight}
                  width={defaults.iconsHeight}
                  event_name='header_tracking'
                  event_action='cart'
                />
              </div>
              <ShowOnDesktop>
                <button
                  className={`flex w-fit items-center px-[9px] py-[3px] outline-none ${
                    isTransparent && lightContent
                      ? 'border border-white bg-transparent'
                      : 'bg-black'
                  } rounded-[9px]`}
                  onClick={() => handleOpenLanguageModal()}
                >
                  <p className='text-10 font-light uppercase leading-normal tracking-[0.36px] text-white'>
                    {i18n.locale?.split('_')[0]}
                  </p>
                </button>
              </ShowOnDesktop>
              <LanguageModal
                countries={i18n.countries}
                locale={i18n.locale}
                show={showLangModal}
                setShow={setShowLangModal}
              />
            </div>
          </div>
        </div>
      </FixedHeaderWrapper>
      <ShowOnDesktop className='relative'>
        <PuroDesktopSearch
          showSearch={showSearch}
          setShowSearch={setShowSearch}
        />
      </ShowOnDesktop>
      <PuroMiniCart
        showMiniCart={inCartPage ? false : showMiniCart}
        setShowMiniCart={setShowMiniCart}
        basket={basket}
        minAmountFreeShipping={minAmountFreeShipping}
        minBoxesFreeDelivery={minBoxesFreeDelivery}
      />
      <ChoiceOfBonusProductsModal />
      <Region region={areas?.get('modal')} />
    </>
  )
}

export default Header
