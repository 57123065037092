import React from 'react'
import { useWindowSize } from 'msp-integrations'
import { ScrollForMore } from '@/components/atoms/ScrollForMore'
import { Headline, Icons, CustomLinkComponent } from 'msp-components'

type LandingHeaderProps = {
  headline: string
  linkText: string
  linkHref?: string
  children: any
}
const LandingHeader = ({
  headline,
  linkText = '',
  linkHref = '',
  children
}: LandingHeaderProps) => {
  const { width, breakpoints } = useWindowSize()
  const isDesktop = width && width > breakpoints.xl

  return (
    <div className='relative h-[74vh] w-full xl:h-screen'>
      <div className='animate-appear-from-left absolute bottom-8 z-[1] flex w-full flex-col pl-4 pr-2 md:bottom-16 md:left-20 md:w-1/2 md:p-0 xl:left-36 xl:w-[33%]'>
        <Icons iconName='DarqLogoMain' width='200px' color='white' />
        <Headline
          tag='h1'
          className='text-48 md:text-52 xl:text-64 md:leading-56 mb-4 mt-6 text-white md:mb-8'
        >
          {headline}
        </Headline>
        {!!linkHref && !!linkText && (
          <div className='w-fit'>
            <CustomLinkComponent
              classname='text-white font-GoodPro font-medium'
              href={linkHref}
              colorScheme='secondary'
            >
              {linkText}
            </CustomLinkComponent>
          </div>
        )}
      </div>
      {children}
      <div
        className={`absolute bottom-0 left-1/2 -translate-x-1/2 select-none transition-all duration-500`}
      >
        {isDesktop && <ScrollForMore />}
      </div>
    </div>
  )
}

export default LandingHeader
