import React from 'react'
import {
  DynamicTag,
  transformRegionToMap,
  ShowOnDesktop,
  MainWrapper
} from 'msp-components'
import { PuroSearchBox } from '@/components/organisms/PuroSearchBox'
import { ServiceSearchProps } from './ServiceSearch.types'
import * as S from './ServiceSearch.styles'

//todo: Remove the overlapping demo div (on line 13) when M23 tab module will get created
const ServiceSearch = ({ data, regions = [] }: ServiceSearchProps) => {
  const headlineText =
    transformRegionToMap(regions)?.get('headlineRegion')?.components || []
  return (
    <>
      <MainWrapper>
        <ShowOnDesktop className={'bg-offWhite h-36 w-full'}></ShowOnDesktop>
        <div className={S.serviceSearchContainer}>
          <div className={S.serviceSearchWrapper}>
            {headlineText.length && (
              <div className={S.dynamicTagWrapper}>
                <DynamicTag
                  className={S.serviceSearchTitle}
                  componentTagName='Headline'
                  colorScheme='whiteAlpha'
                  tag='h6'
                >
                  {headlineText[0].data?.children}
                </DynamicTag>
              </div>
            )}
            <div
              className={S.searchBoxWrapper}
              data-testid='service-search-box-wrapper'
            >
              <PuroSearchBox
                className={S.searchBoxStyles}
                inputClassName={S.inputBoxStyle}
                inputPlaceholder={data?.SearchPlaceholder}
                iconColor={'#FFFFFF'}
                isDarkMode={true}
              />
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  )
}

export default ServiceSearch
