import React from 'react'
import { ConditionalWrapper, Icons } from 'msp-components'
import type { SvgSizeType, IconName } from 'msp-integrations'

type ImageLinkProps = {
  icon?: IconName
  iconSize: SvgSizeType
  manualSize?: string
  image?: string
  imageAlt?: string
  link?: string
  className?: string
  displayType?: string
  colour?: string
}

export const ImageLink = ({
  icon,
  iconSize,
  manualSize = '',
  image = '',
  imageAlt = '',
  link = '',
  className = '',
  displayType = 'inline-flex',
  colour = 'black'
}: ImageLinkProps) => {
  return (
    <div className={`${displayType} ${className}`}>
      <ConditionalWrapper
        condition={link?.length > 0}
        wrapper={(children: any) => <a href={link}>{children}</a>}
      >
        {(image && <img src={image} alt={imageAlt} />) || (
          <Icons
            color={colour}
            height={manualSize}
            size={iconSize}
            iconName={icon}
          />
        )}
      </ConditionalWrapper>
    </div>
  )
}

export default ImageLink
