import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { ShowOnDesktop, Icons, CustomLinkComponent } from 'msp-components'
import { productUrlBuilder } from 'msp-integrations'
import useProduct from '@/hooks/use-product'
import { AmountAttributeIndicator } from '@/components/molecules/AmountAttributeIndicator'
import { CoffeeCategoryTileProps } from './CoffeeCategoryTile.types'

const CoffeeCategoryTile = ({
  productId,
  imageAlt,
  tileImage,
  className = '',
  fontColor = 'white',
  setGlobalTileCSSClass
}: CoffeeCategoryTileProps) => {
  const { formatMessage } = useIntl()

  const [isMouseHovered, setIsMouseHovered] = useState(false)

  const {
    c_flavorIntensity,
    showLoading,
    c_fruitiness,
    c_roasting,
    name,
    c_primaryCategoryName,
    c_primaryCategoryPath,
    c_classCategoryName,
    c_classCategoryPath
  } = useProduct(productId)

  const attributes = [
    {
      name: formatMessage({
        id: 'msp.product.attributes.intensity',
        defaultMessage: 'Intensity'
      }),
      value: c_flavorIntensity || 0
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.fruitiness',
        defaultMessage: 'Acidity'
      }),
      value: c_fruitiness || 0
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.roasting',
        defaultMessage: 'Roasting'
      }),
      value: c_roasting || 0
    }
  ]

  const handleMouseOver = () => {
    if (setGlobalTileCSSClass) setGlobalTileCSSClass('cc-blur-tile')
    setIsMouseHovered(true)
  }

  const handleMouseLeave = () => {
    if (setGlobalTileCSSClass) setGlobalTileCSSClass('')
    setIsMouseHovered(false)
  }

  return (
    <div
      className={`cc-tile-container ${className} ${
        isMouseHovered ? 'cc-unblur-tile' : ''
      }`}
    >
      <div className='cc-tile-image-link-wrapper'>
        <div
          className='cc-tile-image-wrapper'
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {!showLoading && (
            <CustomLinkComponent
              href={productUrlBuilder({
                name: name || '',
                id: productId,
                classCategoryName: c_classCategoryName,
                classCategoryPath: c_classCategoryPath,
                primaryCategoryName: c_primaryCategoryName,
                primaryCategoryPath: c_primaryCategoryPath
              })}
              colorScheme='whiteAlpha'
              className='cc-tile-custom-link'
            >
              <img
                src={tileImage}
                className='cc-tile-image'
                alt={imageAlt || name}
              />
              <div
                className={`cc-tile-link-wrapper ${
                  isMouseHovered ? 'cc-tile-link-wrapper-hover' : ''
                } `}
              >
                <div>
                  <div
                    className={`cc-tile-plus-icon ${
                      isMouseHovered ? 'cc-tile-plus-icon-hover' : ''
                    }`}
                  >
                    <Icons iconName='Close' height='10px' width='10px' />
                  </div>
                </div>
              </div>
            </CustomLinkComponent>
          )}
        </div>
      </div>
      <ShowOnDesktop className='cc-tile-attributes-show-div'>
        <div
          className='cc-tile-attributes-main'
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {attributes?.length > 0 &&
            attributes.map((attr, idx) => (
              <div
                key={`cc-tile-attrib-${idx}`}
                className='cc-tile-attributes-wrapper'
              >
                <p className={`cc-tile-prod-attributes text-${fontColor}`}>
                  {attr.name}
                  {': '}
                </p>
                <AmountAttributeIndicator
                  className={`cc-tile-attributes-amount-indicator fill-${fontColor} stroke-${fontColor}`}
                  quantity={attr.value}
                  height='10px'
                />
              </div>
            ))}
        </div>
      </ShowOnDesktop>
    </div>
  )
}

export default CoffeeCategoryTile
