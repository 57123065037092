import React from 'react'
import { IconsProps } from 'msp-integrations'

const CoffeeBeanIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M10.89,21.51c-1.23,0-2.52-0.35-3.76-1.06c-3.42-1.98-4.61-5.69-2.81-8.84l3.5-6.06c1.83-3.13,5.63-3.97,9.05-2
		c3.42,1.98,4.61,5.69,2.81,8.84l-3.5,6.06C15.02,20.45,13.05,21.51,10.89,21.51z M13.13,3.5c-1.82,0-3.47,0.89-4.44,2.55l-3.5,6.06
		c-1.53,2.69-0.53,5.76,2.44,7.48c2.92,1.68,6.15,1,7.68-1.63l3.5-6.06c1.53-2.69,0.53-5.76-2.44-7.48C15.3,3.8,14.18,3.5,13.13,3.5
		z'
        />
        <path
          d='M14.19,7.27c-0.01,1.78-1.12,3.74-2.44,4.28c-1.7,0.7-3.04,2.98-3.05,5.2l1,0c0.01-1.78,1.12-3.74,2.44-4.28
		c1.7-0.7,3.04-2.98,3.05-5.2L14.19,7.27z'
        />
      </g>
    </svg>
  )
}

export default CoffeeBeanIcon
