import React from 'react'
import { useIntl } from 'react-intl'
import { Icons } from 'msp-components'
import { ExpandVideoProps } from './ExpandVideo.types'

const ExpandVideo = ({ className = '' }: ExpandVideoProps) => {
  const { formatMessage } = useIntl()
  return (
    <div className={`flex flex-row items-center pb-6 ${className}`}>
      <div
        className={
          'font-GoodPro-Wide text-10 leading-12 tracking-3 pr-3 font-medium uppercase text-white'
        }
      >
        {formatMessage({
          id: 'msp.stage_component.expand',
          defaultMessage: 'Expand'
        })}
      </div>
      <div className={'fill-white'}>
        <Icons iconName={'VideoExpand'} height={'25px'} width={'25px'} />
      </div>
    </div>
  )
}

export default ExpandVideo
