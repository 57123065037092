export const serviceSearchContainer =
  'flex flex-col w-full lg:px-44 md:pb-16 md:absolute md:max-w-screen-3xl'

export const serviceSearchWrapper =
  'flex flex-col md:flex-row w-full py-14 px-8 md:pl-20 md:py-20 md:pr-32 bg-black md:mt-[-108px]'

export const dynamicTagWrapper = 'basis-2/5 pb-10 pr-3 md:pb-0 md:m-auto'

export const serviceSearchTitle =
  'text-white font-medium font-GoodPro font-medium tracking-1.2 leading-28'

export const searchBoxWrapper = 'basis-3/5'

export const searchBoxStyles =
  'relative w-full flex-auto font-black text-20 bg-black'

export const inputBoxStyle =
  ' bg-black h-12 md:h-14 border-menuGray text-24 leading-28 placeholder-grayRegular text-gray94'
