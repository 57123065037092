import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
  CustomLinkComponent,
  Headline,
  RecommendationCarousel,
  Spinner,
  EventsEmitter,
  Events
} from 'msp-components'
import ProductTile from '@/components/molecules/ProductTile/ProductTile'
import {
  IProductData,
  useIntersectionObserver,
  useEinstein
} from 'msp-integrations'
import * as T from './EinsteinRecommendations.types'
import * as S from './EinsteinRecommendations.styles'

const EinsteinRecommendations = ({
  data,
  custom
}: T.EinsteinRecommendationsTypes) => {
  const cookiesAllowedEmitter = useRef(EventsEmitter)
  const {
    api,
    recommendations,
    loading,
    getZoneRecommendations,
    getRecommendations,
    sendClickReco,
    sendViewReco
  } = useEinstein()
  const {
    headline,
    actionLinkText,
    recommender,
    category,
    zone,
    product,
    products = [],
    count = 4
  } = data
  const { formatMessage } = useIntl()
  const [_products, setProducts] = useState(products)
  const [renderProducts, setRenderProducts] = useState([])
  const [rcCanBeLoaded, setRcCanBeLoaded] = useState(true)
  const { actionLink } = custom
  const ref = useRef()
  const isOnScreen = useIntersectionObserver(ref, { useOnce: true })

  /**
   * Build Product Tile component
   * @param {IProductData} product Product ID
   * @returns {JSX.Element} Product Tile component
   */
  const buildProductTile = (product: IProductData): JSX.Element => {
    return (
      <ProductTile
        key={`product-tile-${product.id}`}
        data={product}
        onClick={() => {
          sendClickReco(
            {
              recommenderName: recommendations.recommenderName,
              __recoUUID: recommendations.recoUUID
            },
            product
          )
        }}
      />
    )
  }

  useEffect(() => {
    // Return early if we have no Einstein API instance
    if (!api) {
      return
    }

    // Only a product or a category is used at a time, product has precedence
    let recommendationBody = {}
    if (product) {
      recommendationBody = {
        products: [{ id: product }]
      }
    } else if (category) {
      recommendationBody = {
        categories: [{ id: category }]
      }
    }

    // Fetch either zone or recommender, but not both. If a zone and recommender
    // name are both provided, `zone` takes precendence.
    if (zone?.value) {
      setRcCanBeLoaded(
        getZoneRecommendations(zone.value, recommendationBody, _products)
      )
      return
    }
    if (recommender?.value) {
      setRcCanBeLoaded(
        getRecommendations(recommender.value, recommendationBody, _products)
      )
      return
    }
  }, [zone?.value, recommender?.value, _products])

  useEffect(() => {
    // Return early if we have no Einstein API instance
    if (!api) {
      return
    }

    // This is an optimization that eliminates superfluous rerenders/fetching by
    // keeping a copy of the `products` array prop in state for shallow comparison.
    if (!Array.isArray(products)) {
      return
    }
    if (
      products.length !== _products?.length ||
      !products.every((val, index) => val === _products?.[index])
    ) {
      setProducts(products)
    }
  }, [products])

  useEffect(() => {
    if (recommendations?.recs) {
      const cleanedProducts = recommendations?.recs?.map((item: any) => {
        return { ...item, variants: [] }
      })
      setRenderProducts(cleanedProducts.slice(0, count))
    }
  }, [recommendations])

  useEffect(() => {
    if (isOnScreen && recommendations?.recs) {
      sendViewReco(
        {
          recommenderName: recommendations.recommenderName,
          __recoUUID: recommendations.recoUUID
        },
        _products.map((rec) => ({ id: rec.id }))
      )
    }
  }, [isOnScreen, recommendations])

  useEffect(() => {
    if (!cookiesAllowedEmitter.current?.has(Events.CHANGED_CONSENTS)) {
      cookiesAllowedEmitter.current?.subscribe(
        Events.CHANGED_CONSENTS,
        (allowedCookies: any) => {
          if (allowedCookies.marketing) {
            // Fetch either zone or recommender, but not both. If a zone and recommender
            // name are both provided, `zone` takes precendence.
            if (zone?.value) {
              setRcCanBeLoaded(getZoneRecommendations(zone.value, _products))
              return
            }
            if (recommender?.value) {
              setRcCanBeLoaded(getRecommendations(recommender.value, _products))
              return
            }
          }
        }
      )
    }

    return () => {
      cookiesAllowedEmitter.current?.unsubscribe(Events.CHANGED_CONSENTS)
    }
  }, [])

  // Check if we have an Einstein API instance before attempting to render anything
  if (!api) {
    return null
  }

  return (
    <div className={S.EinsteinRecommendationsWrapper} ref={ref}>
      <div className={S.EinsteinRecommendationsHeadWrapper}>
        <Headline tag='h2' className='headline-h5 text-black'>
          {headline}
        </Headline>
        <CustomLinkComponent
          href={actionLink}
          inline={true}
          className={S.EinsteinRecommendationsLinkWrapper}
          uppercase={false}
        >
          {actionLinkText}
        </CustomLinkComponent>
      </div>
      <div className={S.EinsteinRecommendationsCarouselWrapper}>
        {loading ? (
          <div className={S.EinsteinRecommendationsLoadingWrapper}>
            <div className={S.EinsteinRecommendationsLoadingTile}>
              <Spinner />
            </div>
            <div className={S.EinsteinRecommendationsLoadingTile}>
              <Spinner />
            </div>
            <div className={S.EinsteinRecommendationsLoadingTile}>
              <Spinner />
            </div>
            <div className={S.EinsteinRecommendationsLoadingTile}>
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            {rcCanBeLoaded ? (
              <>
                {renderProducts && renderProducts.length > 0 ? (
                  <RecommendationCarousel
                    productTiles={renderProducts.map(buildProductTile)}
                  />
                ) : (
                  <div
                    className={S.EinsteinRecommendationsNoRecommendationsFound}
                  >
                    {formatMessage({
                      id: 'msp.einstein.recommendations.noRecommendationsFound',
                      defaultMessage:
                        'Currently we have no products for recommendation.'
                    })}
                  </div>
                )}
              </>
            ) : (
              <div className={S.EinsteinRecommendationsNoConsent}>
                {formatMessage({
                  id: 'msp.einstein.recommendations.noConsent',
                  defaultMessage:
                    'You have not consented to receive personalized products recommendations.'
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default EinsteinRecommendations
