import React from 'react'
import { Icons } from 'msp-components'

export const sealIconsMapper = (seals?: string[]) => {
  const sealSvgs = {
    bio: <Icons iconName='Bio' width='40px' />,
    fairTrade: <Icons iconName='FairTrade' width='40px' />
  }

  if (!seals || seals.length === 0) return []
  return seals.map((seal) => sealSvgs[seal])
}
