import React from 'react'
import { IconsProps } from 'msp-integrations'

const DarqNuoIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path
        d='M9.98,20.99v-11h3.29l1.7,3.01h3.97l1.7-3.01h1.3v-1H9.98v-3c0-0.83,0.67-1.5,1.5-1.5h4.5v1.52h2V2.01h-2V3.5h-4.5
	c-1.38,0-2.5,1.12-2.5,2.5v3H4v12H2v1h20v-1H9.98z M18.36,12.01h-2.81l-1.14-2.01h5.08L18.36,12.01z M5,9.99h3.98v11H5V9.99z'
      />
    </svg>
  )
}

export default DarqNuoIcon
