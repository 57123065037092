import React from 'react'
import { IconsProps } from 'msp-integrations'

const CaffeineFreeTextIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path
            d='M19.35,14.6l-3.27-3.28l1.87-3.24c1.39-2.44,0.48-5.32-2.18-6.85c-2.65-1.53-5.6-0.88-7.01,1.55L8.3,3.57L5.17,0.42
			L4.62,0.96l3.27,3.3L6.04,7.47c-1.39,2.44-0.48,5.32,2.18,6.85c0.96,0.56,1.96,0.82,2.91,0.82c1.67,0,3.19-0.82,4.1-2.37
			l0.44-0.77l3.12,3.14L19.35,14.6z M14.56,12.39c-1.19,2.04-3.69,2.57-5.95,1.26c-2.3-1.33-3.08-3.71-1.89-5.79l2.71-4.69
			c0.75-1.29,2.03-1.98,3.44-1.98c0.82,0,1.68,0.23,2.51,0.71c2.3,1.33,3.08,3.71,1.89,5.79L14.56,12.39z'
          />
          <path
            d='M11.8,7.43c-1.32,0.54-2.36,2.31-2.37,4.03l0.77,0c0.01-1.38,0.87-2.9,1.89-3.31c1.32-0.54,2.36-2.31,2.37-4.03l-0.77,0
			C13.68,5.49,12.82,7.01,11.8,7.43z'
          />
        </g>
        <g>
          <path d='M2.1,23.54l-1.13-1.82l-0.44,0.55v1.28H0.01v-3.93h0.52v2.08h0.01l1.44-2.08h0.61l-1.28,1.76l1.39,2.18H2.1z' />
          <path
            d='M5.28,22.62c0,0.56-0.37,0.97-1.06,0.97c-0.69,0-1.06-0.41-1.06-0.97v-0.88c0-0.56,0.37-0.98,1.06-0.98
			c0.69,0,1.06,0.41,1.06,0.98V22.62z M3.63,22.6c0,0.39,0.19,0.62,0.59,0.62c0.41,0,0.6-0.24,0.6-0.62v-0.85
			c0-0.39-0.19-0.63-0.6-0.63s-0.59,0.24-0.59,0.63V22.6z'
          />
          <path
            d='M7.33,21.14H6.64v2.4H6.17v-2.4H5.82V20.8h0.35v-0.21c0-0.54,0.29-0.98,1.1-1.01l0.03,0.33c-0.5,0.02-0.68,0.29-0.68,0.68
			v0.21h0.71V21.14z'
          />
          <path
            d='M9.35,21.14H8.66v2.4H8.19v-2.4H7.84V20.8h0.35v-0.21c0-0.54,0.29-0.98,1.1-1.01l0.03,0.33c-0.5,0.02-0.68,0.29-0.68,0.68
			v0.21h0.71V21.14z'
          />
          <path
            d='M10.35,22.2v0.39c0,0.41,0.22,0.64,0.61,0.64c0.29,0,0.47-0.13,0.61-0.46l0.34,0.12c-0.16,0.46-0.47,0.69-0.99,0.69
			c-0.67,0-1.03-0.41-1.03-0.99v-0.87c0-0.55,0.36-0.98,1.01-0.98c0.63,0,1,0.43,1,0.99v0.45H10.35z M10.35,21.9h1.13V21.7
			c0-0.37-0.21-0.59-0.57-0.59c-0.39,0-0.56,0.24-0.56,0.6V21.9z'
          />
          <path d='M13.1,20.8v2.74h-0.47V20.8H13.1z M13.09,19.61v0.58h-0.45v-0.58H13.09z' />
          <path
            d='M14.41,23.54h-0.47V20.8h0.41l0.02,0.29h0.01c0.19-0.22,0.46-0.34,0.78-0.34c0.58,0,0.89,0.4,0.89,1.01v1.78h-0.47v-1.77
			c0-0.41-0.18-0.63-0.55-0.63c-0.29,0-0.5,0.14-0.62,0.31V23.54z'
          />
          <path
            d='M18.15,21.14h-0.69v2.4h-0.47v-2.4h-0.35V20.8h0.35v-0.21c0-0.54,0.29-0.98,1.1-1.01l0.03,0.33
			c-0.5,0.02-0.68,0.29-0.68,0.68v0.21h0.71V21.14z'
          />
          <path
            d='M20.2,21.25c-0.08-0.04-0.17-0.07-0.28-0.07c-0.4,0-0.62,0.32-0.66,0.8v1.56h-0.47V20.8h0.4l0.02,0.5h0.01
			c0.16-0.39,0.4-0.55,0.71-0.55c0.11,0,0.24,0.03,0.33,0.08L20.2,21.25z'
          />
          <path
            d='M21.24,22.2v0.39c0,0.41,0.22,0.64,0.61,0.64c0.29,0,0.47-0.13,0.61-0.46l0.34,0.12c-0.16,0.46-0.47,0.69-0.99,0.69
			c-0.67,0-1.03-0.41-1.03-0.99v-0.87c0-0.55,0.36-0.98,1.01-0.98c0.63,0,1,0.43,1,0.99v0.45H21.24z M21.24,21.9h1.13V21.7
			c0-0.37-0.21-0.59-0.57-0.59c-0.39,0-0.56,0.24-0.56,0.6V21.9z'
          />
          <path d='M23.99,20.8v2.74h-0.47V20.8H23.99z M23.99,19.61v0.58h-0.45v-0.58H23.99z' />
        </g>
      </g>
    </svg>
  )
}

export default CaffeineFreeTextIcon
