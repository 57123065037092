import React from 'react'
import { SideFlyoutContainer } from 'msp-components'
import { PuroSearchBox } from '../PuroSearchBox'
import { HeaderMenuFlyoutProps } from './HeaderMenuFlyout.types'
import HeaderMenu from '../HeaderMenu/HeaderMenu'
import { HeaderMenuBottom } from '../HeaderMenuBottom'
import { ShowOnDesktop, ShowOnMobile, Icons } from 'msp-components'

const title = (
  <div className='flex justify-center md:justify-start md:pl-3'>
    <ShowOnMobile>
      <Icons iconName='DarqLogo' height='18px' width='142px' />
    </ShowOnMobile>
    <ShowOnDesktop>
      <Icons iconName='DarqLogoMain' height='18px' width='96px' />
    </ShowOnDesktop>
  </div>
)

const HeaderMenuFlyout = ({
  className = '',
  isOpen,
  onOpen,
  onClose,
  areas
}: HeaderMenuFlyoutProps) => {
  return (
    <SideFlyoutContainer
      position={'left'}
      width={'w-screen md:w-[478px]'}
      titleContent={title}
      fixedTitle={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <div className={`h-full overflow-auto ${className}`}>
        <PuroSearchBox
          onResultClick={onClose}
          className='ml-[70px] mr-[30px] mt-20 block md:hidden'
          position='absolute'
          showDeleteButton={true}
        />
        <div data-testid={'header-menu-flyout'}>
          <div className='menu-wrapper'>
            <HeaderMenu content={areas?.get('menu')} closeFlyout={onClose} />
          </div>
          <HeaderMenuBottom className='' onSuccess={onClose} />
        </div>
      </div>
    </SideFlyoutContainer>
  )
}

export default HeaderMenuFlyout
