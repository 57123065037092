import React from 'react'
import { MoodBoard, MoodBoardImage } from 'msp-components'
import * as S from './PuroMoodBoard.styles'

const PuroMoodBoard = ({
  headline,
  link,
  images,
  bgColor
}: {
  headline?: string
  link: { href: string; text: string }
  images?: MoodBoardImage[]
  bgColor?: string
}) => {
  return (
    <MoodBoard
      headline={headline}
      link={link}
      images={images}
      imageClassName={S.imageClassName}
      bgColor={bgColor}
      headlineStyle={S.headline}
      linkColorScheme='secondary'
      linkedImageStyle={S.linkedImage}
    />
  )
}

export default PuroMoodBoard
