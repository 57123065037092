import { useIntl } from 'react-intl'

export const getCoffeeProperties = ({
  c_caffeine,
  c_specialties,
  c_aromatisation,
  c_coffeeOrigin,
  c_mountingHeight,
  c_taste,
  c_sorte
}: {
  c_caffeine?: boolean | null
  c_specialties?: string
  c_aromatisation?: string
  c_coffeeOrigin?: Array<string>
  c_mountingHeight?: string
  c_taste: string
  c_sorte: string
}) => {
  const { formatMessage } = useIntl()
  const translations = [
    typeof c_caffeine === 'boolean' && {
      key: formatMessage({
        id: 'msp.product.properties.caffeine',
        defaultMessage: 'Caffeine'
      }),
      value: c_caffeine
        ? formatMessage({
            id: 'msp.product.properties.caffeineYes',
            defaultMessage: 'Yes'
          })
        : formatMessage({
            id: 'msp.product.properties.caffeineNo',
            defaultMessage: 'No'
          })
    },
    !!c_specialties && {
      key: formatMessage({
        id: 'msp.product.properties.specialties',
        defaultMessage: 'Specialties'
      }),
      value: c_specialties
    },
    !!c_coffeeOrigin && {
      key: formatMessage({
        id: 'msp.product.properties.origin',
        defaultMessage: 'Origin'
      }),
      value: c_coffeeOrigin.join(', ')
    },
    !!c_mountingHeight && {
      key: formatMessage({
        id: 'msp.product.properties.altitude',
        defaultMessage: 'Altitude'
      }),
      value: c_mountingHeight
    },
    !!c_taste && {
      key: formatMessage({
        id: 'msp.product.properties.taste',
        defaultMessage: 'Taste'
      }),
      value: c_taste
    },
    !!c_sorte && {
      key: formatMessage({
        id: 'msp.product.properties.sorte',
        defaultMessage: 'Sorte'
      }),
      value: c_sorte
    }
  ].filter((elem) => typeof elem === 'object' && Object.keys(elem).length > 0)

  if (translations.length > 0 || c_aromatisation) {
    translations.splice(1, 0, {
      key: formatMessage({
        id: 'msp.product.properties.aromatisation',
        defaultMessage: 'Aromatisation'
      }),
      value: c_aromatisation || '-'
    })
  }

  return translations
}

export const getIngredientsAndAllergens = ({
  c_ingredients,
  c_allergens
}: {
  c_ingredients?: string
  c_allergens?: string
}) => {
  const { formatMessage } = useIntl()
  const translations = [
    !!c_ingredients && {
      key: formatMessage({
        id: 'msp.product.properties.ingredients',
        defaultMessage: 'Ingredients'
      }),
      value: c_ingredients
    },
    !!c_allergens && {
      key: formatMessage({
        id: 'msp.product.properties.allergens',
        defaultMessage: 'Allergens'
      }),
      value: c_allergens
    }
  ]
  return translations.filter(
    (elem) => typeof elem === 'object' && Object.keys(elem).length > 0
  )
}
