import React from 'react'
import PuroMoodBoard from '@/components/organisms/PuroMoodBoard/PuroMoodBoard'
import { Region, transformRegionToMap, useMultiSite } from 'msp-integrations'

const MoodBoardWrapper = ({
  data,
  regions,
  custom
}: {
  data: any
  regions: any
  custom: any
}) => {
  const { buildUrl } = useMultiSite()

  if (custom?.content?.link) {
    data.link = custom?.content?.link
  }

  const isExternal = data.link.indexOf('://') !== -1
  if (!isExternal && data.link !== '/') {
    data.link = buildUrl(data.link)
  }

  const images = transformRegionToMap(regions)?.get('images').components

  const moodBoardImages = images.map((image: any) => {
    const bubble = transformRegionToMap(image?.regions).get('content')
    const imageDimensions = image?.data?.image?.meta_data || {
      width: 500,
      height: 500
    }
    return {
      url: image.custom?.content?.image?.path,
      alt: image.data.imageAlt,
      link: image.custom?.content?.link,
      linkTitle: image.data.linkTitle,
      ...(bubble?.components && {
        hotSpotBubble: (
          <Region
            region={bubble}
            imageDimensions={imageDimensions}
            activeIndex={-1}
          />
        )
      })
    }
  })

  return (
    <PuroMoodBoard
      headline={data.headline}
      link={{ href: data.link, text: data.linkText }}
      images={moodBoardImages}
      bgColor={data.bgColor}
    />
  )
}

export default MoodBoardWrapper
