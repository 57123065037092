import loadable from '@loadable/component'
import { ComponentMapping } from 'msp-integrations'
import { defaultComponentMapping } from 'msp-components'
import Anchor from '@/components/atoms/Anchor/Anchor'
import Discovery from '@/components/molecules/Discovery/Discovery'
import PuroCommerceBox from '@/components/molecules/PuroCommerceBox/PuroCommerceBox'
import PuroContentWithBg from '@/components/molecules/PuroContentWithBg/PuroContentWithBg'
import ProductTileComponent from '@/components/molecules/ProductTile/ProductTile'
import Header from './header'
import ImageLinkWrapper from './ImageLinkWrapper'
import MoodBoardWrapper from './MoodBoardWrapper/MoodBoardWrapper'
import EinsteinRecommendations from '@/components/organisms/EinsteinRecommendations/EinsteinRecommendations'
import NavItemComponent from './NavItem/NavItem'
import ProductDetailsWrapper from './ProductDetailsWrapper'
import SpacerWrapper from './SpacerWrapper'
import { CoffeeCategoryGrid } from '@/components/molecules/CoffeeCategoryGrid'
import ServiceSearch from '@/components/organisms/ServiceSearch/ServiceSearch'
import ServiceContact from '@/components/molecules/ServiceContact/ServiceContact'
import VideoStandaloneComponent from '@/components/organisms/VideoStandaloneComponent/VideoStandaloneComponent'
import LandingHeaderWrapper from './LandingHeaderWrapper/LandingHeaderWrapper'
import Stage from '@/components/organisms/Stage/Stage'
import FullWidthIntroWrapper from './FullWidthIntroWrapper/FullWidthIntroWrapper'
import GalleryTextWrapper from './GalleryTextWrapper'
import CustomRecommendation from '@/components/organisms/CustomRecommendation/CustomRecommendation'
import MachineCommerceStageWrapper from './MachineCommerceStageWrapper/MachineCommerceStageWrapper'
import PuroFooter from '@/components/organisms/PuroFooter/PuroFooter'
import PuroRecipe from '../organisms/PuroRecipe/PuroRecipe'

const JasminCartPromotionSlot = loadable(
  () => import('@/components/organisms/CartPromotionSlot/CartPromotionSlot')
)

const componentMapping: ComponentMapping = {
  ...defaultComponentMapping,
  ...{
    'pwa.anchor': Anchor,
    'pwa.cloudinaryVideo': VideoStandaloneComponent,
    'pwa.coffeeCategoryGrid': CoffeeCategoryGrid,
    'pwa.commerceBox': PuroCommerceBox,
    'pwa.contentWithBackground': PuroContentWithBg,
    'pwa.customRecommendation': CustomRecommendation,
    'pwa.discovery': Discovery,
    'pwa.einsteinRecommendations': EinsteinRecommendations,
    'pwa.fullWidthIntro': FullWidthIntroWrapper,
    'pwa.galleryText': GalleryTextWrapper,
    'pwa.Header': Header,
    'pwa.image': ImageLinkWrapper,
    'pwa.landingHeader': LandingHeaderWrapper,
    'pwa.moodBoard': MoodBoardWrapper,
    'pwa.navItem': NavItemComponent,
    'pwa.productDetails': ProductDetailsWrapper,
    'pwa.productTile': ProductTileComponent,
    'pwa.serviceContact': ServiceContact,
    'pwa.ServiceSearch': ServiceSearch,
    'pwa.spacer': SpacerWrapper,
    'pwa.stage': Stage,
    'pwa.machineCommerceStage': MachineCommerceStageWrapper,
    'pwa.Footer': PuroFooter,
    'pwa.recipe': PuroRecipe,
    'pwa.cartPromotionSlot': JasminCartPromotionSlot
  }
}

export default componentMapping
