import React from 'react'
import { IconsProps } from 'msp-integrations'

const HexagonFilledIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <polygon points='12,1.92 3.12,6.96 3.12,17.04 12,22.08 20.88,17.04 20.88,6.96 	' />
      </g>
    </svg>
  )
}

export default HexagonFilledIcon
