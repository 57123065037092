import React from 'react'
import uniqueId from 'lodash/uniqueId'
import {
  DynamicTag,
  transformRegionToMap,
  Grid,
  BaseRichTextComponent,
  CustomLinkComponent
} from 'msp-components'
import { ImageLink } from '@/components/atoms/ImageLink/ImageLink'
import { handleLinkClick } from './ServiceContact.helper'
import { ServiceContactProps } from './ServiceContact.types'
import * as S from './ServiceContact.styles'

const ServiceContact = ({ regions = [] }: ServiceContactProps) => {
  const regionsNames = ['contactBox1', 'contactBox2', 'contactBox3']
  const regionsColors = ['bg-coffee1-100', 'bg-coffee1-50', 'bg-coffee1-25']
  const contactBoxRegions = regionsNames.map(
    (regionName: string, index: number) => ({
      contactBoxInfo:
        transformRegionToMap(regions)?.get(regionName)?.components || [],
      bgColor: regionsColors[index]
    })
  )
  return (
    <div data-testid='grid-wrapper'>
      <Grid wrapperStyles={S.gridWrapper}>
        {contactBoxRegions.map((row: any) => (
          <ContactGridElement
            key={uniqueId('serviceContact')}
            contactBoxInfo={row?.contactBoxInfo}
            bgColor={row?.bgColor}
          />
        ))}
      </Grid>
    </div>
  )
}
const ContactGridElement = ({ contactBoxInfo, bgColor }: any) => {
  const [image, dynamicTagLink, richText, linkDetails] = contactBoxInfo
  let linkHref = ''
  let isExternalLink = false
  if (linkDetails?.data?.linkType === 'Phone') {
    linkHref = 'tel:' + linkDetails?.data?.linkText
    isExternalLink = true
  }

  return (
    <div className={`${bgColor} ${S.contactBox}`}>
      {image?.data?.icon && (
        <div className={S.imageWrapper}>
          <ImageLink {...image?.data} displayType={'block'} />
        </div>
      )}
      {dynamicTagLink?.data && (
        <div className={S.linkWrapper}>
          <DynamicTag
            className={S.linkStyles}
            componentTagName='Headline'
            colorScheme='whiteAlpha'
            tag='h6'
          >
            {dynamicTagLink?.data?.children}
          </DynamicTag>
        </div>
      )}
      {richText?.data && (
        <div className={S.textWrapper}>
          <BaseRichTextComponent
            className={S.textStyles}
            data={{
              richText: richText?.data?.richText,
              textColor: richText?.data?.textColor
            }}
          />
        </div>
      )}
      {linkDetails?.data?.linkText && (
        <div
          onClick={() =>
            handleLinkClick(
              linkDetails?.data?.linkType,
              linkDetails?.data?.linkHref
            )
          }
        >
          <CustomLinkComponent
            href={linkHref}
            colorScheme='primary'
            isExternalLink={isExternalLink}
          >
            {linkDetails?.data?.linkText}
          </CustomLinkComponent>
        </div>
      )}
    </div>
  )
}

export default ServiceContact
