import React from 'react'
import { IconsProps } from 'msp-integrations'

const BasketIcon = (props: IconsProps) => {
  return (
    <svg
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      {...props}
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M16.03,5.99V5.71C16.02,3.56,14.33,2,12.02,2C9.71,2,8.02,3.56,8.01,5.71v0.28H4V22H20V5.99H16.03z M9.01,5.72
	   C9.02,4.12,10.26,3,12.02,3c1.77,0,3.01,1.12,3.01,2.71v0.28H9.01V5.72z M19,21H5V6.99h3.02v2.97h1V6.99h6.02v2.97h1V6.99H19V21z'
        />
      </g>
    </svg>
  )
}

export default BasketIcon
