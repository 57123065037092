import React from 'react'
import { ContentWithBg } from 'msp-components'
import { Region, transformRegionToMap } from 'msp-integrations'

type ContentWihBgProps = {
  data: any
  custom: any
  regions: Array<any>
}

const PuroContentWihBg = (props: ContentWihBgProps) => {
  const content = transformRegionToMap(props.regions).get('content')

  const data = Object.assign({}, props.data || props || {})
  if (props.custom?.content?.bgImage?.path) {
    data.bgImage.path = props.custom?.content?.bgImage?.path
  }

  return (
    <ContentWithBg {...data}>
      {content && <Region region={content} />}
    </ContentWithBg>
  )
}

export default PuroContentWihBg
