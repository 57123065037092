import React from 'react'
import { IconsProps } from 'msp-integrations'

const DiamondIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <path
          d='M1.5,9.16L12,22.17L22.5,9.16H1.5z M8.31,10.16h7.36L12,19.25L8.31,10.16z M16.75,10.16h3.66l-7.32,9.07L16.75,10.16z
		 M7.23,10.16l3.68,9.07l-7.32-9.07H7.23z'
        />
      </g>
    </svg>
  )
}

export default DiamondIcon
