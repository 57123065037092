import React from 'react'
import { Region, transformRegionToMap } from 'msp-integrations'
import LandingHeader from '@/components/organisms/LandingHeader/LandingHeader'

const LandingHeaderWrapper = ({
  data,
  custom,
  regions
}: {
  data: any
  custom: any
  regions: any
}) => {
  const videoRegion = transformRegionToMap(regions).get('cloudinaryVideoRegion')
  const linkHref = custom.content.link

  return (
    <LandingHeader
      headline={data.headline}
      linkText={data.linkText}
      linkHref={linkHref}
    >
      <Region className='h-full' region={videoRegion} />
    </LandingHeader>
  )
}

export default LandingHeaderWrapper
