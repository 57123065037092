import React, { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { CustomLinkComponent, AlertBox } from 'msp-components'
import PuroCommerceBox from '@/components/molecules/PuroCommerceBox/PuroCommerceBox'
import { twMerge } from 'tailwind-merge'
import {
  findImageGroupBy,
  getImageFromImageGroup,
  productUrlBuilder,
  ProductImage
} from 'msp-integrations'
import useProduct from '@/hooks/use-product'
import * as S from './ProductTile.styles'
import { ProductTileTypes } from './ProductTile.types'

const ProductTile = ({ data, onClick, parentCarousel }: ProductTileTypes) => {
  const alertBoxRef = useRef<AlertBox>()
  const [alertBoxMessage, setAlertBoxMessage] = useState({
    type: 'error',
    message: ''
  })
  const isProductObject = !!data.id && !!data.slugUrl
  const { productId } = data
  const [productImage, setProductImage] = useState({} as ProductImage)

  if (!productId && !isProductObject) {
    return <></>
  }

  const product = useProduct(isProductObject ? data : productId)

  const onShowAlert = (alert: { type: string; message: string }) => {
    setAlertBoxMessage(alert)
    alertBoxRef?.current?.showAlert()
  }

  useEffect(() => {
    if (product) {
      product.name = product.productName || product.name
    }

    if (product?.imageGroups) {
      const largeImageGroup = findImageGroupBy(product.imageGroups, {
        viewType: 'large',
        selectedVariationAttributes: {}
      })

      setProductImage({
        link: getImageFromImageGroup(largeImageGroup, 0, {
          scaleWidth: 322
        }),
        alt: largeImageGroup?.images[0]?.alt || product.name
      })
    }
  }, [product?.imageGroups])

  return (
    <>
      <AlertBox
        ref={alertBoxRef}
        type={alertBoxMessage.type}
        title={alertBoxMessage.message}
        autoCloseTime={2000}
        position='bottom'
        showBackdrop={false}
      />
      {!product.showLoading && (
        <div
          className={twMerge(
            S.TileWrapper,
            parentCarousel === 'cartPromotionSlot'
              ? 'w-[300px] lg:w-64 2xl:w-80'
              : 'w-[300px] xl:w-80'
          )}
        >
          <div className={S.HeadWrapper}>
            <div className={S.ImageWrapper}>
              <LazyLoadImage
                src={productImage.link}
                alt={productImage.alt}
                width='324'
                height='285'
                className={S.Image}
              />
            </div>
            <div className={S.ImageBadgesWrapper}></div>
          </div>
          <div className={S.InformationWrapper}>
            <div className={S.ContentWrapper}>
              {product.name && (
                <CustomLinkComponent
                  href={productUrlBuilder({
                    name: product?.name,
                    id: productId || product?.id,
                    classCategoryName: product?.c_classCategoryName,
                    primaryCategoryName: product?.c_primaryCategoryName,
                    classCategoryPath: product?.c_classCategoryPath,
                    primaryCategoryPath: product?.c_primaryCategoryPath
                  })}
                  inline={true}
                  onClick={onClick}
                >
                  <div className={S.ProductName}>{product.name}</div>
                </CustomLinkComponent>
              )}
              {/* Check for the subtitle */}
              <div className={S.ProductDescription}>
                {product.c_servings || product.shortDescription || ''}
              </div>
            </div>
            <div className={S.ActionsWrapper}>
              <PuroCommerceBox
                noVariants
                smallDiscountPrice
                showTaxesInfo={false}
                data={{
                  product: { ...product, variants: [] },
                  type: 'MD',
                  addButtonAsIcon: 'XS'
                }}
                onWishlistChange={onShowAlert}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProductTile
