import React from 'react'
import {
  DynamicTag,
  ShowOnMobile,
  BaseRichTextComponent,
  Icons,
  Button
} from 'msp-components'
import { isMobile, useNavigation } from 'msp-integrations'
import { ImageLink } from '@/components/atoms/ImageLink/ImageLink'
import * as S from './Discovery.styles'
import { ActiveTileInfoProps, ImageTileProps } from './Discovery.types'

export const ImageTile = ({
  activeTile,
  setActiveTile,
  activeImageId,
  discoveryItemsRegions
}: ImageTileProps) => {
  const [headlineData, sublineData, imageData] =
    discoveryItemsRegions?.itemInfo || []
  const ScrollToActive = (id: string) => {
    setTimeout(() => {
      const node = document.querySelector(`#${id}`) as HTMLElement
      if (node) {
        node.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }, 600)
  }
  return (
    <div
      className={'relative cursor-pointer'}
      onClick={() => {
        discoveryItemsRegions.showState =
          activeImageId === imageData?.id
            ? !activeTile.showState
            : !discoveryItemsRegions.showState
        setActiveTile(discoveryItemsRegions)
        if (discoveryItemsRegions.showState && isMobile())
          ScrollToActive('imgid-' + imageData?.id)
      }}
    >
      <div id={`imgid-${imageData?.id}`} className={'lg:overflow-hidden'}>
        <img
          className={`duration-400 w-full transition-all ${
            activeImageId !== imageData?.id
              ? 'lg:scale-110 lg:blur'
              : 'lg:scale-100 lg:blur-none'
          }`}
          src={imageData?.custom.content?.image?.path}
          alt={headlineData?.data?.children}
        />
      </div>
      <div className={S.imageTextWrapper}>
        <div className={S.imageTextHeadlineWrapper}>
          <DynamicTag
            className={S.imageTextHeadline}
            componentTagName='Headline'
            colorScheme='whiteAlpha'
            tag='h6'
          >
            {headlineData?.data?.children}
          </DynamicTag>
          <p className={S.imageTextSubline}>{sublineData?.data?.children}</p>
        </div>
        <ShowOnMobile className={S.imageIconsWrapper}>
          <Icons
            width='10px'
            height='6px'
            iconName={
              activeImageId === imageData?.id && activeTile.showState
                ? 'ArrowUpFilled'
                : 'ArrowDownFilled'
            }
            size={'xl'}
          />
        </ShowOnMobile>
      </div>
    </div>
  )
}
export const GetActiveTileInfo = ({
  activeTileInfo,
  wrapperId = ''
}: ActiveTileInfoProps) => {
  const navigate = useNavigation()
  const [
    headlineData,
    sublineData,
    ,
    richTextData,
    buttonData,
    icon1Data,
    icon2Data,
    icon3Data
  ] = activeTileInfo?.itemInfo || []
  return (
    <div id={wrapperId} className={S.tileInfoWrapper}>
      <DynamicTag componentTagName='Preline'>
        {headlineData.data?.children}
      </DynamicTag>
      <DynamicTag
        className={S.tileInfoHeadline}
        componentTagName='Headline'
        colorScheme='whiteAlpha'
        tag='h4'
      >
        {sublineData?.data?.children}
      </DynamicTag>
      <BaseRichTextComponent
        className={`${S.tileInfoRichText} hide-scrollbar`}
        data={richTextData?.data}
      />
      {icon1Data?.data?.icon && (
        <div className={S.illustrationWrapper}>
          <ImageLink
            {...icon1Data?.data}
            className={S.iconsCommonStyle}
            displayType='block'
          />
          {icon2Data?.data?.icon && (
            <ImageLink
              {...icon2Data?.data}
              className={S.iconsCommonStyle}
              displayType='block'
            />
          )}
          {icon3Data?.data?.icon && (
            <ImageLink
              {...icon3Data?.data}
              className={S.iconsCommonStyle}
              displayType='block'
            />
          )}
        </div>
      )}
      {buttonData?.data?.buttonText && (
        <div data-testid='discoveryButton' className={S.puroButtonWrapper}>
          <Button
            variant={'outline'}
            type={'primary'}
            onClick={() => navigate(buttonData?.custom?.content?.buttonLink)}
          >
            {buttonData?.data?.buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}
