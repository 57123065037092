import React from 'react'
import { useIntl } from 'react-intl'
import { ProductHeader, VariantProps } from 'msp-components'
import { sealIconsMapper } from '../../../utils/product.utils'
import PuroCommerceBox from '../PuroCommerceBox/PuroCommerceBox'
import { useBasket } from 'msp-integrations'
import { twMerge } from 'tailwind-merge'
import { MIN_BOXES_ORDER, MIN_BOXES_FREE_DELIVERY } from '@/constants'

const PuroProductHeader = ({
  product,
  variant,
  onVariantChange
}: {
  product: any
  variant?: VariantProps
  onVariantChange?: (productId: string) => void
}) => {
  const { formatMessage } = useIntl()
  const basket = useBasket()
  const { c_minBoxesFreeDelivery, c_minBoxesOrder } = basket
  const minBoxesFreeDelivery = c_minBoxesFreeDelivery ?? MIN_BOXES_FREE_DELIVERY
  const minBoxesOrder = c_minBoxesOrder ?? MIN_BOXES_ORDER

  const ProductHeaderStyles = {
    container:
      'hide-scrollbar h-fit w-full pt-12 lg:pt-24 lg:px-0 mb-8 lg:mb-0 mx-auto flex flex-col font-GoodPro font-medium overflow-scroll',
    wrapper: 'relative mx-auto w-10/12 lg:w-96',
    tag: 'table text-14 leading-normal tracking-normal bg-black text-white font-GoodPro font-normal py-2 px-3 w-fit',
    preline:
      'table text-12 leading-[14px] tracking-[4px] uppercase font-light font-GoodPro-Wide w-fit',
    productName: 'text-36 leading-44 tracking-0.8 mb-8 mt-4',
    shortDescription:
      'text-[15px] leading-24 tracking-[0.6px] mb-3 font-GoodPro font-medium',
    longDescription:
      'text-[15px] leading-24 tracking-[0.6px] break-words hyphens-auto font-GoodPro font-normal relative mb-5 text-justify',
    sealsContainer:
      'flex-row flex absolute right-0 top-2 h-fit w-fit -translate-y-2/4 md:top-0 md:translate-y-0 md:translate-x-full md:flex-col md:pl-4',
    attributeIcons: 'rotate-90',
    attributeKeyClasses: 'font-GoodPro-Wide font-medium'
  }

  const seals = sealIconsMapper(product?.c_seal)

  const attributes = [
    {
      name: formatMessage({
        id: 'msp.product.attributes.intensity',
        defaultMessage: 'Intensity'
      }),
      value: product?.c_flavorIntensity
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.fruitiness',
        defaultMessage: 'Acidity'
      }),
      value: product?.c_fruitiness
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.roasting',
        defaultMessage: 'Roasting'
      }),
      value: product?.c_roasting
    }
  ]

  const messagesShipping = {
    minimumOrderQuantityMessage: formatMessage({
      id: 'msp.cart.shipment.minimum.order.value.term',
      defaultMessage: 'minimum order quantity'
    }),
    teaBoxMessage: formatMessage({
      id: 'msp.cart.shipment.brewbox.multiple',
      defaultMessage: 'Coffee boxes'
    }),
    freeShippingMessage: formatMessage({
      id: 'msp.cart.shipment.minimum.free.shipping.term',
      defaultMessage: 'Free shipping'
    }),
    fromMessage: formatMessage({
      id: 'msp.cart.shipment.free.shipping.from',
      defaultMessage: 'From'
    })
  }

  const InfoMessage = ({
    boldMessage,
    message,
    value,
    from
  }: {
    boldMessage: string
    message: string
    value: number
    from?: string
  }) => (
    <div>
      <span className='font-bold'>{boldMessage}</span>
      <span>
        {from ? (
          ` ${from} ${value}`
        ) : (
          <>
            <span className='font-bold'>{`: `}</span> <span>{value}</span>
          </>
        )}{' '}
        {message}
      </span>
    </div>
  )

  return (
    <>
      <ProductHeader
        attributes={attributes}
        classification={product?.c_coffeeClassification}
        longDescription={product?.longDescription}
        name={product?.name}
        promotionTag={product?.c_promotionMessage}
        seals={seals}
        shortDescription={product?.shortDescription}
        styling={{ ...ProductHeaderStyles }}
      />
      <div className={twMerge('mx-auto mt-10', ProductHeaderStyles.wrapper)}>
        <PuroCommerceBox
          isPDP={true}
          data={{
            showAnchorLink: true,
            product: {
              ...product,
              variant
            },
            type: 'LG'
          }}
          onVariantChange={onVariantChange}
          noPadding
          showLowestPrice
        />
        {product.c_cartCategoryId === 'coffee' &&
        (minBoxesOrder || minBoxesFreeDelivery) ? (
          <div className='bg-secondaryCoffee1 text-14 font-Roboto tracking-1 mx-auto mt-9 flex w-10/12 flex-col gap-3 p-4 uppercase text-black lg:w-96'>
            {minBoxesOrder ? (
              <InfoMessage
                boldMessage={messagesShipping.minimumOrderQuantityMessage}
                message={messagesShipping.teaBoxMessage}
                value={minBoxesOrder}
              />
            ) : null}
            {minBoxesFreeDelivery ? (
              <InfoMessage
                boldMessage={messagesShipping.freeShippingMessage}
                from={messagesShipping.fromMessage}
                message={messagesShipping.teaBoxMessage}
                value={minBoxesFreeDelivery}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default PuroProductHeader
