import React from 'react'
import { Carousel } from 'msp-components'
import { Region, useWindowSize } from 'msp-integrations'
import { GalleryTextProps } from './GalleryText.types'
import * as S from './GalleryText.styles'

const GalleryText = ({
  galleryPosition = 'left',
  images,
  content
}: GalleryTextProps) => {
  const mobileWrapper = `flex ${
    galleryPosition === 'left' ? 'flex-col' : 'flex-col-reverse'
  }`
  const desktopWrapper = `flex ${
    galleryPosition === 'left' ? 'flex-row' : 'flex-row-reverse'
  }`

  const { width, breakpoints } = useWindowSize()
  const isMobile = width && width < breakpoints.md

  return (
    <div className={`${isMobile ? mobileWrapper : desktopWrapper}`}>
      <div className={`${S.carouselWrapper}`}>
        <Carousel
          productImages={images}
          autoplay
          hideControl={images.length < 2}
        />
      </div>
      <div
        className={`${S.contentWrapper} ${
          galleryPosition === 'right' ? 'md:mr-auto' : ''
        }`}
      >
        <Region region={content} />
      </div>
    </div>
  )
}

export default GalleryText
