import React from 'react'
import { IconsProps } from 'msp-integrations'

const WishlistAddIcon = (props: IconsProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.074 4.81111C15.097 2.80911 18.132 2.92811 20.292 5.08911C22.459 7.25611 22.58 10.2951 20.579 12.3161L19.868 11.6111C21.489 9.97511 21.373 7.58311 19.585 5.79611C17.801 4.01211 15.413 3.90111 13.779 5.52011L12.001 7.30011L10.221 5.51811C8.58603 3.89911 6.19403 4.01611 4.40803 5.80411C2.62603 7.58711 2.51303 9.97511 4.13403 11.6111L11.989 19.4701L13.783 17.6821L14.488 18.3901L11.987 20.8831L3.42503 12.3171C1.42203 10.2951 1.53903 7.25911 3.70103 5.09711C5.86503 2.93011 8.90603 2.80911 10.926 4.80911L12.001 5.88511L13.074 4.81111ZM16.0127 11.3801H17.0127V13.8871H19.5307V14.8871H17.0127V17.3681H16.0127V14.8871H13.4867V13.8871H16.0127V11.3801Z'
      />
    </svg>
  )
}

export default WishlistAddIcon
