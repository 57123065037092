import React from 'react'
import { ImageLink } from '@/components/atoms/ImageLink/ImageLink'
import { transformRegionToMap } from 'msp-components'

const ImageLinkWrapper = ({
  data,
  regions,
  custom
}: {
  data: any
  regions: any
  custom: any
}) => {
  const content = transformRegionToMap(regions)?.get('content')
  const imageSrc = custom?.content?.image?.path

  return (
    <ImageLink
      content={content}
      {...data}
      link={custom?.content?.link}
      image={imageSrc}
    />
  )
}

export default ImageLinkWrapper
