import React from 'react'
import { Region } from 'msp-integrations'
import { HeaderMenuProps } from './HeaderMenu.types'

const HeaderMenu = ({ className, content, closeFlyout }: HeaderMenuProps) => {
  return (
    <div
      className={`mb-4 ml-[70px] mr-7 mt-5 border-b-[1px] border-black pb-3 md:mb-6 md:ml-[90px] md:mr-10 md:mt-32 ${className}`}
    >
      <Region
        region={content}
        closeFlyout={closeFlyout}
        eventName='navigation'
      />
    </div>
  )
}

export default HeaderMenu
