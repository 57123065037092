import React from 'react'
import { Icons, Image } from 'msp-components'
import useProduct from '@/hooks/use-product'
import PuroCommerceBox from '@/components/molecules/PuroCommerceBox/PuroCommerceBox'
import { ProductFlipCardProps } from './ProductFlipCard.types'

const ProductFlipCard = ({
  className = '',
  productCoverImageUrl,
  productId,
  imageAlt,
  productDetailImageUrl
}: ProductFlipCardProps) => {
  const product = useProduct(productId)

  return (
    <div className={`snap-center ${className}`}>
      <div
        className={
          'flip-container flip-card-perspective relative h-[400px] w-[300px] lg:h-[24vw] lg:min-h-[200px] lg:w-[18vw] lg:min-w-[150px]'
        }
      >
        <div
          className={
            'flip-card preserve-3d absolute h-full w-full transition-all duration-500 ease-in-out'
          }
        >
          <div
            className={
              'card-front backface-hidden absolute h-full w-full select-none'
            }
          >
            <div className={'relative h-full w-full overflow-hidden'}>
              <div className={'absolute left-5 top-4 fill-white'}>
                <div className={'rotate-45'}>
                  <Icons iconName={'Close'} width={'15px'} height={'15px'} />
                </div>
              </div>
              <Image
                className={'h-full w-full object-cover'}
                src={productCoverImageUrl}
                alt={`${imageAlt || product?.name} cover`}
                ratio='1/4'
              />
            </div>
          </div>
          <div
            className={
              'rotate-y-180 backface-hidden absolute h-full w-full overflow-hidden bg-white'
            }
          >
            <div className={'flex h-full flex-col'}>
              <div className={'h-full w-full flex-1 overflow-hidden'}>
                <Image
                  className={'h-full w-full object-cover'}
                  src={productDetailImageUrl || productCoverImageUrl}
                  alt={imageAlt || product?.name}
                  ratio='1/4'
                />
              </div>
              <div className={'border-gray92 min-h-[30%] flex-none border'}>
                <PuroCommerceBox
                  data={{
                    productId: productId,
                    type: 'TILE'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductFlipCard
