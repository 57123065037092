import React from 'react'
import { IconsProps } from 'msp-integrations'

const CupWithSteamIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <rect x='11.16' y='1.22' width='1' height='6.17' />
        <rect x='8.09' y='3.28' width='1' height='4.11' />
        <rect x='14.25' y='3.28' width='1' height='4.11' />
        <path
          d='M19.34,10.47h-0.39c0.16-0.82,0.26-1.38,0.28-1.46l0.11-0.59H3.99l0.12,0.6c0,0,1.62,8.15,1.91,9.66
		c0.37,1.89,1.55,3.06,3.09,3.06h5.1c1.47,0,2.59-1.11,3.08-3.03c0.13-0.52,0.38-1.73,0.66-3.12h1.39c1.41,0,2.55-1.14,2.55-2.55
		S20.74,10.47,19.34,10.47z M16.32,18.45c-0.21,0.85-0.77,2.28-2.11,2.28H9.1c-1.54,0-2-1.72-2.11-2.25
		c-0.25-1.26-1.41-7.15-1.79-9.06h12.92C17.77,11.31,16.64,17.14,16.32,18.45z M19.34,14.57h-1.19c0.21-1.07,0.42-2.16,0.6-3.1h0.59
		c0.85,0,1.55,0.7,1.55,1.55S20.19,14.57,19.34,14.57z'
        />
      </g>
    </svg>
  )
}

export default CupWithSteamIcon
