import React from 'react'
import { IconsProps } from 'msp-integrations'

const AboTextIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <rect x='159.14' y='173.43' fill='#FDEEF6' width='24' height='24' />
        <rect x='161.14' y='175.43' fill='#FFFFFF' width='20' height='20' />
        <g>
          <path
            fill='#1D1D1B'
            d='M168.21,180.6c-1.99,1.15-2.68,3.32-1.62,5.17l1.92,3.33l0,0c0.68,1.17,1.83,1.79,3.09,1.79
			c0.71,0,1.46-0.2,2.18-0.62c1.99-1.15,2.68-3.32,1.62-5.17l-1.92-3.33C172.42,179.95,170.2,179.45,168.21,180.6z M173.28,189.41
			c-1.51,0.88-3.12,0.54-3.91-0.81l-1.92-3.32c-0.78-1.36-0.26-2.93,1.26-3.8c1.51-0.87,3.12-0.54,3.91,0.81l1.92,3.33
			C175.31,186.97,174.8,188.53,173.28,189.41z'
          />
          <path
            fill='#1D1D1B'
            d='M171.19,184.98c-0.62-0.26-1.17-1.26-1.17-2.14l-1,0c0.01,1.3,0.79,2.64,1.79,3.06
			c0.62,0.26,1.17,1.26,1.17,2.14l1,0C172.97,186.74,172.18,185.39,171.19,184.98'
          />
          <path
            fill='#1D1D1B'
            d='M175.43,193.07c-2.04,1.18-4.42,1.49-6.69,0.88c-2.28-0.61-4.18-2.07-5.36-4.11
			c-1.18-2.04-1.49-4.42-0.88-6.69c0.61-2.28,2.07-4.18,4.11-5.36c2.04-1.18,4.42-1.49,6.69-0.88c2.1,0.56,3.87,1.85,5.05,3.64
			h-2.44l0.58,1h3.07l1.53-2.7l-0.58-1.01l-1.27,2.24c-1.32-2.04-3.32-3.5-5.68-4.14c-2.54-0.68-5.18-0.33-7.45,0.98
			c-2.27,1.31-3.9,3.43-4.58,5.96c-0.68,2.53-0.33,5.18,0.98,7.45c1.31,2.27,3.43,3.9,5.96,4.58c0.85,0.23,1.7,0.34,2.56,0.34
			c1.7,0,3.38-0.45,4.9-1.32c2.15-1.24,3.71-3.21,4.45-5.56h-1.05C178.63,190.36,177.27,192.01,175.43,193.07z'
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M9.66,3.84C8.13,4.72,7.6,6.39,8.41,7.8l1.47,2.56l0,0c0.52,0.9,1.41,1.38,2.38,1.38c0.55,0,1.12-0.15,1.68-0.47
			c1.53-0.88,2.05-2.55,1.25-3.97l-1.48-2.56C12.89,3.34,11.18,2.96,9.66,3.84z M13.55,10.6c-1.16,0.67-2.4,0.41-3-0.63L9.08,7.42
			c-0.6-1.05-0.2-2.25,0.97-2.92c1.16-0.67,2.4-0.41,3,0.62l1.47,2.55C15.11,8.73,14.72,9.93,13.55,10.6z'
          />
          <path
            d='M11.95,7.2c-0.48-0.2-0.9-0.97-0.9-1.64l-0.77,0c0,1,0.61,2.03,1.37,2.35c0.48,0.2,0.9,0.97,0.9,1.64l0.77,0
			C13.31,8.55,12.71,7.52,11.95,7.2'
          />
          <path
            d='M15.2,13.41c-1.57,0.9-3.39,1.14-5.14,0.68c-1.75-0.47-3.21-1.59-4.11-3.16C5.04,9.37,4.8,7.54,5.27,5.79
			c0.47-1.75,1.59-3.21,3.16-4.11C9.99,0.77,11.82,0.53,13.57,1c1.61,0.43,2.97,1.42,3.88,2.8h-1.87l0.44,0.77h2.36l1.17-2.08
			L19.1,1.72l-0.97,1.72c-1.02-1.57-2.55-2.69-4.36-3.18c-1.95-0.52-3.98-0.25-5.72,0.75C6.3,2.02,5.05,3.65,4.53,5.59
			c-0.52,1.95-0.25,3.98,0.75,5.72c1.01,1.74,2.63,2.99,4.58,3.51c0.65,0.17,1.31,0.26,1.96,0.26c1.31,0,2.6-0.34,3.76-1.01
			c1.65-0.95,2.85-2.46,3.42-4.27H18.2C17.66,11.33,16.61,12.6,15.2,13.41z'
          />
        </g>
        <g>
          <path
            d='M1.72,19.27c0.85,0,1.33,0.51,1.33,1.33v1.24c0,0.81-0.48,1.32-1.33,1.32h-1.3v-3.9H1.72z M1.72,19.71H0.94v3.03h0.78
			c0.54,0,0.82-0.29,0.82-0.9v-1.2C2.54,20.01,2.27,19.71,1.72,19.71z'
          />
          <path d='M4.1,23.17H3.57l1.29-3.9h0.56l1.29,3.9H6.18l-0.31-0.99H4.4L4.1,23.17z M4.52,21.78h1.23l-0.61-2.03H5.13L4.52,21.78z' />
          <path
            d='M8.52,21.5H7.95v1.67H7.43v-3.9h1.19c0.77,0,1.13,0.4,1.13,1.08c0,0.59-0.26,0.96-0.74,1.07L10,23.17H9.42L8.52,21.5z
			 M7.95,21.1H8.6c0.5,0,0.64-0.27,0.64-0.72c0-0.46-0.16-0.68-0.63-0.68H7.95V21.1z'
          />
          <path
            d='M13.2,20.54v1.36c0,0.68-0.31,1.14-0.9,1.28v0.01l0.63,0.61L12.48,24l-0.67-0.77C11,23.2,10.57,22.7,10.57,21.9v-1.36
			c0-0.82,0.46-1.32,1.32-1.32C12.73,19.22,13.2,19.72,13.2,20.54z M12.68,20.54c0-0.6-0.26-0.89-0.8-0.89
			c-0.53,0-0.8,0.29-0.8,0.89v1.36c0,0.6,0.26,0.9,0.8,0.9c0.53,0,0.8-0.3,0.8-0.9V20.54z'
          />
          <path
            d='M15.44,23.17h-0.53l1.29-3.9h0.56l1.29,3.9h-0.53l-0.31-0.99h-1.47L15.44,23.17z M15.86,21.78h1.23l-0.61-2.03h-0.01
			L15.86,21.78z'
          />
          <path
            d='M19.16,22.9h-0.01l-0.02,0.27h-0.41v-4.02h0.46v1.57h0.01c0.18-0.2,0.44-0.31,0.75-0.31c0.56,0,0.87,0.39,0.87,1.01v0.8
			c0,0.6-0.31,1-0.9,1C19.6,23.21,19.35,23.11,19.16,22.9z M19.18,22.53c0.12,0.18,0.32,0.31,0.61,0.31c0.37,0,0.55-0.23,0.55-0.63
			v-0.8c0-0.4-0.18-0.62-0.55-0.62c-0.28,0-0.5,0.14-0.62,0.31V22.53z'
          />
          <path
            d='M23.57,22.26c0,0.55-0.37,0.96-1.05,0.96c-0.68,0-1.05-0.4-1.05-0.96v-0.88c0-0.56,0.37-0.97,1.05-0.97
			c0.69,0,1.05,0.41,1.05,0.97V22.26z M21.94,22.24c0,0.38,0.19,0.62,0.59,0.62c0.4,0,0.59-0.24,0.59-0.62V21.4
			c0-0.39-0.19-0.63-0.59-0.63s-0.59,0.24-0.59,0.63V22.24z'
          />
        </g>
      </g>
    </svg>
  )
}
export default AboTextIcon
