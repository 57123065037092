import React from 'react'
import { IconsProps } from 'msp-integrations'

const CaffeineFreeIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g>
        <g>
          <path
            d='M21.5,20.81l-4.22-4.24l2.41-4.18c1.8-3.15,0.61-6.86-2.81-8.84c-3.42-1.97-7.22-1.13-9.05,2L7.24,6.56L3.21,2.5L2.5,3.2
			l4.22,4.26l-2.39,4.14c-1.8,3.15-0.61,6.86,2.81,8.84c1.24,0.72,2.53,1.06,3.76,1.06c2.16,0,4.12-1.06,5.29-3.06l0.57-0.99
			l4.03,4.05L21.5,20.81z M15.32,17.95c-1.54,2.63-4.77,3.32-7.68,1.63c-2.97-1.72-3.98-4.79-2.44-7.48l3.5-6.06
			c0.97-1.66,2.62-2.55,4.44-2.55c1.06,0,2.17,0.3,3.24,0.92c2.97,1.72,3.98,4.79,2.44,7.48L15.32,17.95z'
          />
          <path
            d='M11.76,11.55c-1.7,0.7-3.04,2.98-3.05,5.2l1,0c0.01-1.78,1.12-3.74,2.44-4.28c1.7-0.7,3.04-2.98,3.05-5.2l-1,0
			C14.19,9.05,13.07,11.01,11.76,11.55z'
          />
        </g>
      </g>
    </svg>
  )
}

export default CaffeineFreeIcon
