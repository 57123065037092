import { useIntl } from 'react-intl'
import {
  useProduct as originalUseProduct,
  OUT_OF_STOCK,
  UNFULFILLABLE
} from 'msp-integrations'

const useProduct = (item, isProductPartOfSet = false) => {
  const intl = useIntl()

  const product = originalUseProduct(item, isProductPartOfSet)
  const stockLevel = product?.c_inventory?.stockLevel || 0

  const isOutOfStock =
    !stockLevel ||
    (!product?.variant &&
      Object.keys(product?.variationParams).length ===
        product?.variationAttributes.length)
  const unfulfillable = stockLevel < product?.quantity
  const inventoryMessages = {
    [OUT_OF_STOCK]: intl.formatMessage({
      defaultMessage: 'Out of stock',
      id: 'use_product.message.out_of_stock'
    }),
    [UNFULFILLABLE]: intl.formatMessage(
      {
        defaultMessage: 'Only {stockLevel} left!',
        id: 'use_product.message.inventory_remaining'
      },
      { stockLevel }
    )
  }
  const showInventoryMessage =
    product?.variant && (isOutOfStock || unfulfillable)
  const inventoryMessage =
    (isOutOfStock && inventoryMessages[OUT_OF_STOCK]) ||
    (unfulfillable && inventoryMessages[UNFULFILLABLE])

  return {
    ...product,
    showInventoryMessage,
    inventoryMessage
  }
}

export default useProduct
