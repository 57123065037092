import React from 'react'
import { useIntl } from 'react-intl'
import { ScrollForMoreProps } from './ScrollForMore.types'

const ScrollForMore = ({ className, isDarkMode }: ScrollForMoreProps) => {
  const { formatMessage } = useIntl()
  return (
    <div className={`flex flex-col justify-items-center ${className}`}>
      <div
        className={`font-GoodPro-Wide text-10 leading-12 tracking-3 pb-2 font-medium uppercase ${
          isDarkMode ? 'text-black' : 'text-white'
        }`}
      >
        {formatMessage({
          id: 'msp.stage_component.scroll_down',
          defaultMessage: 'Scroll Down'
        })}
      </div>
      <div className={'relative h-10'}>
        <div
          className={`absolute top-0 bottom-0 left-1/2 h-full w-0.5 overflow-hidden bg-opacity-20 ${
            isDarkMode ? 'bg-black' : 'bg-white'
          }`}
        >
          <div
            className={`animate-scroll-for-more h-1/2 w-full ease-out ${
              isDarkMode ? 'bg-black' : 'bg-white'
            }`}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ScrollForMore
