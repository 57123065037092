import React, { useEffect, useState } from 'react'
import {
  findImageGroupBy,
  getImageFromImageGroup,
  ProductImage
} from 'msp-integrations'
import { Recipe } from 'msp-components'
import useProduct from '@/hooks/use-product'
import PruoProductHeader from '@/components/molecules/PuroProductHeader/PuroProductHeader'
import type { PuroRecipeProps } from './PuroRecipe.types'

const PuronRecipe = ({ data,custom }: PuroRecipeProps) => {
  const product = data?.relatedProduct
    ? useProduct(data?.relatedProduct)
    : undefined
  const [productImage, setProductImage] = useState<ProductImage>({})

  useEffect(() => {
    if (product?.imageGroups) {
      const largeImageGroup = findImageGroupBy(product.imageGroups, {
        viewType: 'large',
        selectedVariationAttributes: {}
      })
      setProductImage({
        link: getImageFromImageGroup(largeImageGroup, 0, {
          scaleWidth: 960
        }),
        alt: largeImageGroup?.images[0]?.alt || product.name
      })
    }
  }, [product?.imageGroups])

  return (
    <Recipe
      data={data}
      product={<PruoProductHeader product={product} />}
      loading={product.showLoading}
      img={productImage}
      recipeImg={custom?.content?.image?.path}
    />
  )
}

export default PuronRecipe
