import React from 'react'
import { IconsProps } from 'msp-integrations'

const ThermalMugIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path
        d='M18.9,2.23H5.1v1.44l1.08,1.84l0,10.4l1.45,2.5v3.37h8.77v-3.37l1.48-2.5l0-10.45l1.03-1.78V2.23z M15.4,18.13v2.64H8.62
	v-2.64l-1.45-2.5l0-9.96h9.7l0,9.96L15.4,18.13z M17.17,4.67H6.84L6,3.23h12.01L17.17,4.67z'
      />
    </svg>
  )
}

export default ThermalMugIcon
